import Right from "../right";
import Bottom from "./Bottom";
import CapacityBlock from "./CapacityBlock";

export default function Left() {
    return (
        <div className="left ">
            <CapacityBlock />
            <Bottom />
            {/* <Right /> */}
        </div>
    );
}
