import { Route, Routes } from "react-router-dom";
import MiniHead from "../Components/MoneyFlow/MiniHead";
import WithdrawList from "../Components/MoneyFlow/Withdraw";

export default function MoneyFlow() {
    return (
        <div className="page-block money-flow">
            <div className="width-content">
                <MiniHead />
                <Routes>
                    <Route index element={<WithdrawList />} />
                    <Route path="topups" element={<h1>Top Up</h1>} />
                </Routes>
            </div>
        </div>
    );
}
