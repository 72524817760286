import { useState } from "react";
import { DateRange, DateRangePicker } from "react-date-range";

export default function PickDate({
    datePick = false,
    setDatePick,
    setShowDatePick,
}) {
    const [dt, setDt] = useState(
        datePick
            ? [datePick]
            : [
                  {
                      startDate: new Date(),
                      endDate: new Date(),
                      key: "selection",
                  },
              ]
    );
    return (
        <div className="date-pick">
            <div
                className="close"
                onClick={() => {
                    // console.log(dt);
                    if (datePick === dt[0]) {
                        setDatePick(false);
                    } else {
                        setDatePick(dt[0]);
                    }
                    setShowDatePick(false);
                }}
            />
            {/* <DateRangePicker className={"clndr"} /> */}
            <DateRange
                className={"clndr"}
                editableDateInputs={true}
                onChange={(item) => {
                    // console.log(item);

                    setDt([item.selection]);
                }}
                moveRangeOnFirstSelection={false}
                ranges={dt}
            />
        </div>
    );
}
