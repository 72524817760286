import { useState } from "react";
import useRequestsMethods from "../../../hook/useRequestsMethods";
import { useMutation } from "@tanstack/react-query";
import { ReactComponent as CapacityPoolSvg } from "../svg/capacityOutput.svg";
import { ReactComponent as OptionsArrowSvg } from "../svg/OptionArrow.svg";
import { toast } from "react-toastify";

export default function OptionController() {
    const Options = {
        IRON: 1,
        ALUMINUM: 2,
        STEEL: 3,
        COPPER: 4,
        TITANIUM: 5,
        BRONZE: 6,
        SILVER: 7,
        GOLD: 8,
        PLATINUM: 9,
        PULSAR: 10,
    };

    return (
        <>
            <BoostFilling
                Options={Options}
                chekClassForList={chekClassForList}
            />
            <ChangeOptionPrice
                Options={Options}
                chekClassForList={chekClassForList}
            />
        </>
    );
    function chekClassForList(type, check = "") {
        if (type === check) return "active";
        return "";
    }
}
function ChangeOptionPrice({ Options, chekClassForList }) {
    const [newPrice, setPrice] = useState("");
    const [selectedOption, setSelectedOption] = useState(false);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(false);

    const { postUpdate_price_option } = useRequestsMethods();

    const changeUpdate_price_option = useMutation({
        mutationFn: postUpdate_price_option,
    });
    return (
        <div className="big-block bg-block">
            <h2>
                <CapacityPoolSvg /> Change Opt Price
            </h2>

            <div className="row">
                <div className="select-name">
                    <div className="headlist" style={{ minWidth: "100px" }}>
                        {selectedOption || "---"} <OptionsArrowSvg />
                    </div>
                    <ul className="list">{renderList(Options)}</ul>
                </div>
            </div>

            <div className="row">
                <div className="inputs">
                    <label htmlFor="multiplier">Enter price</label>
                    <input
                        type="text"
                        placeholder="000"
                        value={newPrice}
                        onChange={({ target }) => {
                            const input = target.value.replace(/[^0-9.]/g, "");
                            setPrice(input);
                        }}
                    />
                </div>
                <button onClick={goChange}>
                    <span>Set price</span>
                </button>
            </div>
        </div>
    );
    function goChange() {
        if (!newPrice && !selectedOption) return;
        const dataToSend = {
            paymentName: selectedOptionIndex,
            value: newPrice,
        };

        changeUpdate_price_option.mutate(dataToSend, {
            onSuccess: (data) => {
                console.log(data);
                toast.success(`${selectedOption} price change to ${newPrice}`);
                setPrice("");
            },
            onError: (err) => {
                console.log(err);
                toast.error("Error");
            },
        });
    }

    function renderList(list) {
        const keys = Object.keys(list);

        const opts = keys.map((i, n) => {
            if (n <= 2) return false;
            return (
                <li
                    key={`list_${n}`}
                    onClick={() => {
                        setSelectedOption(i);
                        setSelectedOptionIndex(`O${list[i]}`);
                    }}
                >
                    {i}
                </li>
            );
        });
        const packs = ["P1", "P2", "P7"];
        packs.map((i) => {
            opts.push(
                <li
                    key={`list_${i}`}
                    className={chekClassForList(selectedOption, i)}
                    onClick={() => {
                        setSelectedOption(i);
                        setSelectedOptionIndex(i);
                    }}
                >
                    {i}
                </li>
            );
        });
        return opts.filter((i) => i);
    }
}
function BoostFilling({ Options, chekClassForList }) {
    const [selectedOption, setSelectedOption] = useState(false);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(false);
    const [fillingRate, setFillingRate] = useState("");

    const { postBoost, postUpdate_filling_rate } = useRequestsMethods();

    const changeBoost = useMutation({
        mutationFn: postBoost,
    });
    const changeUpdate_filling_rate = useMutation({
        mutationFn: postUpdate_filling_rate,
    });

    return (
        <div className="big-block bg-block">
            <h2>
                <CapacityPoolSvg /> boost + upd filling
            </h2>

            <div className="row">
                <div className="select-name">
                    <div className="headlist" style={{ minWidth: "100px" }}>
                        {selectedOption || "---"} <OptionsArrowSvg />
                    </div>
                    <ul className="list">{renderList(Options)}</ul>
                </div>
            </div>
            <div className="row">
                <button
                    onClick={() => {
                        goBoost(true);
                    }}
                >
                    <span>Boost On</span>
                </button>
                <button
                    className="dec"
                    onClick={() => {
                        goBoost(false);
                    }}
                >
                    <span>Boost Off</span>
                </button>
            </div>
            <div className="row">
                <div className="inputs">
                    <label htmlFor="multiplier">Enter multiplier</label>
                    <input
                        type="text"
                        placeholder="000%"
                        value={fillingRate}
                        onChange={({ target }) => {
                            const input = target.value.replace(/[^0-9.]/g, "");
                            setFillingRate(input);
                        }}
                    />
                </div>
                <button onClick={goFilling}>
                    <span>Set fill</span>
                </button>
            </div>
        </div>
    );

    function goFilling() {
        if (!selectedOptionIndex || !fillingRate) return;

        changeUpdate_filling_rate.mutate(
            {
                optionNumber: selectedOptionIndex,
                nextFillingRate: (fillingRate / 100).toFixed(2),
            },
            {
                onSuccess: () => {
                    toast.success(
                        `${selectedOption} filling set to: ${fillingRate}`
                    );
                },
                onError: (err) => {
                    console.log(err);
                    toast.error(`Error`);
                },
            }
        );
    }
    function goBoost(st) {
        if (!selectedOptionIndex) return;
        changeBoost.mutate(
            {
                optionNumber: selectedOptionIndex,
                boost: st,
            },
            {
                onSuccess: () => {
                    toast.success(
                        `${selectedOption} boost: ${
                            st ? "enabled" : "disabled"
                        }`
                    );
                },
                onError: (err) => {
                    console.log(err);
                    toast.error(`Error`);
                },
            }
        );
    }

    function renderList(list) {
        const keys = Object.keys(list);

        return keys.map((i, n) => {
            return (
                <li
                    key={`list_${n}`}
                    className={chekClassForList(selectedOption, i)}
                    onClick={() => {
                        setSelectedOption(i);
                        setSelectedOptionIndex(list[i]);
                    }}
                >
                    {i}
                </li>
            );
        });
    }
}
