export default function Show({ children }) {
    // console.log(children);
    Show.displayElse = true;

    return children;
}

Show.else = ({ children }) => {
    // console.log(Show.displayElse);
    return Show.displayElse ? children : null;
};
Show.if = function ({ rule, children }) {
    if (rule) {
        Show.displayElse = false;
        return children;
    }
    return null;
};
