import { createContext, useState } from "react";
// import { useSelector } from "react-redux";
import { ReactComponent as CloseSvg } from "./svg/close.svg";

export const ConfirmContext = createContext(null);

function ConfirmProvider({ children }) {
    const [text, setText] = useState("Confirm?");

    const [promise, setPromise] = useState(null);

    const confirm = (text = "Confirm?") => {
        setText(text);
        return new Promise((resolve, reject) => {
            setPromise({ resolve });
        });
    };

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        promise?.resolve(true);
        handleClose();
    };

    const handleCancel = () => {
        promise?.resolve(false);
        handleClose();
    };

    function ConfirmWindow({ text = "asdasd", confirm, cancel, promise }) {
        // if (!promise)
        //     return (
        //         <div className="confirm-window closed-window">
        //             <div className="title">
        //                 <span>Answer Accepted</span>
        //             </div>
        //             <div className="btns">
        //                 <button>
        //                     <span>Cancel</span>
        //                 </button>
        //                 <button>
        //                     <span>Confirm</span>
        //                 </button>
        //             </div>
        //         </div>
        //     );
        return (
            <div
                className={`confirm-window ${!promise ? "closed-window" : ""}`}
            >
                <div className="close-lar" onClick={cancel} />
                <div className="confirm-block">
                    <div className="title">
                        <span>{text}</span>
                        <span className="close" onClick={cancel}>
                            <CloseSvg />
                        </span>
                    </div>
                    <div className="btns">
                        <button onClick={cancel} className="cancel">
                            <span>Cancel</span>
                        </button>
                        <button onClick={confirm} className="confirm">
                            <span>Confirm</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    const val = {
        confirm,
        ConfirmWindow: () => {
            return (
                <ConfirmWindow
                    promise={promise}
                    text={text}
                    confirm={handleConfirm}
                    cancel={handleCancel}
                />
            );
        },
    };
    return (
        <ConfirmContext.Provider value={val}>
            {children}
        </ConfirmContext.Provider>
    );
}

export default ConfirmProvider;
