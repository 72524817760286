import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogoSvg } from "./svg/logo.svg";

import { ReactComponent as OActionsSvg } from "./svg/outfill/actions.svg";
import { ReactComponent as OAdminsSvg } from "./svg/outfill/admins.svg";
import { ReactComponent as OManageSvg } from "./svg/outfill/manage.svg";
import { ReactComponent as OStatSvg } from "./svg/outfill/stata.svg";
import { ReactComponent as OTransferSvg } from "./svg/outfill/transfer.svg";

import { ReactComponent as FActionsSvg } from "./svg/filled/actions.svg";
import { ReactComponent as FAdminsSvg } from "./svg/filled/admins.svg";
import { ReactComponent as FManageSvg } from "./svg/filled/manage.svg";
import { ReactComponent as FStatSvg } from "./svg/filled/stata.svg";
import { ReactComponent as FTransferSvg } from "./svg/filled/transfer.svg";
import Show from "./Showif";
import { useSelector } from "react-redux";

export default function Sidebar({ openTransfer, transferCheck }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { role: adminRole = "" } = useSelector(
        (state) => state.persistedReducer.user
    );
    // console.log(adminRole);

    return (
        <>
            <div className="sidebar" id="sidebar">
                <div
                    className="logo"
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <div className="bg" />
                    <LogoSvg />
                </div>
                <div className="menu-head">Menu</div>
                <ul className="menu">
                    <Show>
                        <li
                            className={checkClass("/stata")}
                            onClick={() => {
                                navigate("/stata");
                            }}
                        >
                            <div className="svgs">
                                <OStatSvg />
                                <FStatSvg />
                            </div>
                            Statistics
                        </li>
                        <Show.if
                            rule={
                                adminRole === "ROLE_SUPER_ADMIN" ||
                                adminRole === "ROLE_MANAGING_ADMIN"
                            }
                        >
                            <li
                                className={checkClass("/manage")}
                                onClick={() => {
                                    navigate("/manage");
                                }}
                            >
                                <div className="svgs">
                                    <OManageSvg />
                                    <FManageSvg />
                                </div>
                                Manage
                            </li>
                        </Show.if>
                        <Show.if rule={adminRole === "ROLE_SUPER_ADMIN"}>
                            <li
                                className={checkClass("/admins")}
                                onClick={() => {
                                    navigate("/admins");
                                }}
                            >
                                <div className="svgs">
                                    <OAdminsSvg />
                                    <FAdminsSvg />
                                </div>
                                Admins
                            </li>
                            <li
                                className={checkClass("/actions")}
                                onClick={() => {
                                    navigate("/actions");
                                }}
                            >
                                <div className="svgs">
                                    <OActionsSvg />
                                    <FActionsSvg />
                                </div>
                                Actions
                            </li>
                            {/* <li
                                className={transferCheck ? "active" : ""}
                                onClick={() => {
                                    // navigate("/transfer");
                                    openTransfer(true);
                                }}
                            >
                                <div className="svgs">
                                    <OTransferSvg />
                                    <FTransferSvg />
                                </div>
                                Transfer funds
                            </li> */}

                            <li
                                className={checkClass("/users")}
                                onClick={() => {
                                    navigate("/users");
                                }}
                            >
                                <div className="svgs">
                                    <OAdminsSvg />
                                    <FAdminsSvg />
                                </div>
                                Users
                            </li>
                            <li
                                className={checkClass("/money-flow")}
                                onClick={() => {
                                    navigate("/money-flow");
                                }}
                            >
                                <div className="svgs">
                                    <OAdminsSvg />
                                    <FAdminsSvg />
                                </div>
                                Money Flow
                            </li>
                        </Show.if>
                        {/* <Show.else> Some default</Show.else> */}
                    </Show>
                </ul>
            </div>
        </>
    );

    function checkClass(path) {
        // console.log(pathname);
        // if (transfer) return "";
        if (transferCheck) return;
        if (path === "/") {
            if (pathname === "/") return "active";
        } else {
            if (pathname.includes(path)) return "active";
        }

        return "";
    }
}
