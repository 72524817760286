import { useLocation, useNavigate } from "react-router-dom";

export default function MiniHead() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <div className="page-mini-header">
            <div className="width-content">
                <ul>
                    <li
                        className={`${
                            pathname === "/money-flow" ? "active" : ""
                        }`}
                        onClick={() => {
                            navigate("/money-flow");
                        }}
                    >
                        Withdraw
                    </li>
                    <li
                        className={`${
                            pathname === "/money-flow/topups" ? "active" : ""
                        }`}
                        onClick={() => {
                            navigate("/money-flow/topups");
                        }}
                    >
                        Top Up
                    </li>
                </ul>
            </div>
        </div>
    );
}
