import { useState } from "react";
import useConfirm from "../../hook/useConfirm";
import { ReactComponent as EditSvg } from "./svg/edit.svg";
import { ReactComponent as TrashSvg } from "./svg/trash.svg";
import { ReactComponent as CloseSvg } from "./svg/close.svg";
import { toast } from "react-toastify";
import moment from "moment";

export default function UserList({ users, changeRole, deleteUser }) {
    const [changeUser, setChangeUser] = useState(false);

    const { confirm } = useConfirm();

    return (
        <div className="user-list">
            <div className="list-head">
                <div className="key">Name</div>
                <div className="key">Date added</div>
                <div className="key">Last active</div>
                <div className="key" />
            </div>
            {renderListItems()}
            {changeUser && (
                <RenderChangeLayout
                    user={changeUser}
                    changeUserState={setChangeUser}
                    changeRole={changeRole}
                />
            )}
        </div>
    );

    function renderListItems() {
        if (users.length === 0) {
            return <div className="list-item">There is no admins yet</div>;
        }

        return users.map((item, index) => {
            let dateCreated = item?.date ? item.date : "---";
            if (dateCreated)
                dateCreated = `${dateCreated[0]}/${dateCreated[1]}/${dateCreated[2]}`;
            let dateActive = item?.lastActive ? item.lastActive : "---";
            if (dateActive)
                dateActive = `${dateActive[0]}/${dateActive[1]}/${dateActive[2]}`;
            return (
                <div className="list-item" key={index}>
                    <div className="key key-name">
                        <div className="img">
                            <img
                                src={item.img ? item.img : "/src/avatar.svg"}
                                alt=""
                            />
                        </div>
                        <div className="name">
                            <span>{item.name}</span>
                            <span>{item.mail}</span>
                        </div>
                    </div>
                    <div className="key">{dateCreated}</div>
                    <div className="key">{dateActive}</div>
                    <div className="key key-functions">
                        <span
                            onClick={() => {
                                handleDeleteUser(item.id);
                            }}
                        >
                            <TrashSvg />
                        </span>
                        <span
                            onClick={() => {
                                setChangeUser(item);
                            }}
                        >
                            <EditSvg />
                        </span>
                    </div>
                </div>
            );
        });
    }

    async function handleDeleteUser(id) {
        const answer = await confirm("Are you sure?");
        if (answer) {
            // console.log("delete " + id);
            deleteUser.mutate(id, {
                onSuccess: ({ data }) => {
                    console.log(data);
                    toast.success(data);
                    // changeUserState(false);
                },
                onError: (error) => {
                    console.error(error);
                    const msg = error.response.data;
                    toast.error(msg);
                },
            });
        } else {
            console.log("not delete " + id);
        }
    }
}

function RenderChangeLayout({ user, changeUserState, changeRole }) {
    const [role, setRole] = useState(user.role);
    return (
        <div className="typical-layout">
            <div className="close-lar" onClick={() => changeUserState(false)} />
            <div className="confirm-block">
                <div className="title">
                    <span>Change role</span>
                    <span
                        className="close"
                        onClick={() => changeUserState(false)}
                    >
                        <CloseSvg />
                    </span>
                </div>
                <div className="radio-list">
                    <div className="inp">
                        <input
                            type="radio"
                            name="role"
                            id="ROLE_ADMIN"
                            onChange={manualChange}
                        />
                        <label htmlFor="ROLE_ADMIN">
                            <div
                                className={`self-radio ${
                                    role === "ROLE_ADMIN" ? "checked" : ""
                                }`}
                            />
                            <span>Admin</span>
                        </label>
                    </div>
                    <div className="inp">
                        <input
                            type="radio"
                            name="role"
                            id="ROLE_MANAGING_ADMIN"
                            onChange={manualChange}
                        />
                        <label htmlFor="ROLE_MANAGING_ADMIN">
                            <div
                                className={`self-radio ${
                                    role === "ROLE_MANAGING_ADMIN"
                                        ? "checked"
                                        : ""
                                }`}
                            />
                            <span>Managing admin</span>
                        </label>
                    </div>
                    {/* <div className="inp">
                        <input
                            type="radio"
                            name="role"
                            id="ROLE_SUPER_ADMIN"
                            onChange={manualChange}
                        />
                        <label htmlFor="ROLE_SUPER_ADMIN">
                            <div
                                className={`self-radio ${
                                    role === "ROLE_SUPER_ADMIN" ? "checked" : ""
                                }`}
                            />
                            <span>Super admin</span>
                        </label>
                    </div> */}
                </div>
                <div className="btns">
                    <button
                        onClick={() => {
                            changeUserState(false);
                        }}
                        className="cancel"
                    >
                        <span>Discard</span>
                    </button>
                    <button onClick={goChange} className="confirm">
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>
    );

    function goChange() {
        changeRole.mutate(
            { role, email: user.mail },
            {
                onSuccess: ({ data }) => {
                    // console.log(data);
                    changeUserState(false);
                    toast.success(data);
                },
                onError: (error) => {
                    console.error(error);
                    const msg = error.response.data;
                    toast.error(msg);
                },
            }
        );
    }

    function manualChange({ target }) {
        setRole(target.id);
    }
}
