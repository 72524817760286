import { createContext, useState } from "react";
// import { ReactComponent as InfoSvg } from "./svg/info.svg";
// import useTranslate from "../hook/useTranslate";

export const PopUpContext = createContext();

export default function PopUpProvider({ children }) {
    const [popUpData, setPopUpData] = useState(null);
    const [popUpBody, setPopBody] = useState({
        type: "info",
        title: "Confirm ?",
        text: <></>,
        btn_text: "Confirm",
        btn_icon: false,
        cancel_btn: true,
        cancel_btn_text: "Cancel",

        apr_func: () => {
            return true;
        },
        dec_func: () => {
            return false;
        },
    });

    async function openPopUp(body = popUpBody) {
        setPopBody((prev) => ({ ...prev, ...body }));
        return new Promise((resolve) => {
            setPopUpData({
                resolve: resolve,
            });
        });
    }

    function closePopUp(data) {
        if (popUpData && popUpData.resolve) {
            popUpData.resolve(data);
        }
        setPopUpData(null);
        setWindowToDefault();
    }

    return (
        <PopUpContext.Provider value={openPopUp}>
            <div
                className={`r-popup ${popUpData ? "show_pop" : ""}`}
                onClick={() => {
                    if (!popUpBody.cancel_btn) return;
                    closePopUp(false);
                }}
            >
                <div
                    className="popup-container"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="title">{popUpBody.title}</div>
                    <div className="text">{popUpBody.text}</div>
                    <div className="btns">
                        <button
                            className="confirm"
                            onClick={() => {
                                closePopUp(popUpBody?.apr_func() || true);
                            }}
                        >
                            {popUpBody.btn_text}
                            {popUpBody.btn_icon ? popUpBody.btn_icon : ""}
                        </button>
                        {popUpBody.cancel_btn && (
                            <button
                                className="desagree"
                                onClick={() => {
                                    closePopUp(popUpBody?.dec_func() || false);
                                }}
                            >
                                {popUpBody.cancel_btn_text}
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {children}
        </PopUpContext.Provider>
    );
    function setWindowToDefault() {
        setPopBody({
            type: "info",
            title: "Confirm ?",
            text: <></>,
            btn_text: "Confirm",
            btn_icon: false,
            cancel_btn: true,
            cancel_btn_text: "Cancel",

            apr_func: () => {
                return true;
            },
            dec_func: () => {
                return false;
            },
        });
    }
}
