import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./Components/Layouts";
import Enter from "./Components/Enter";
import Statistics from "./Pages/Statistics";
import Actions from "./Pages/Actions";
import Admins from "./Pages/Admins";
import useConfirm from "./hook/useConfirm";
import Manage from "./Pages/Manage";
import { useSelector } from "react-redux";
import NotFound from "./Components/Layouts/NotFound";
import MoneyFlow from "./Pages/MoneyFlow";
import UsersPage from "./Pages/Users";

function App() {
    const { ConfirmWindow } = useConfirm();
    const { role: adminRole = "" } = useSelector(
        (state) => state.persistedReducer.user
    );

    return (
        <>
            <Routes>
                <Route path="/" element={<Enter />} />
                <Route element={<Layout />}>
                    <Route path="/stata/*" element={<Statistics />} />
                    <Route
                        element={
                            <CheckRights
                                rule={
                                    adminRole === "ROLE_SUPER_ADMIN" ||
                                    adminRole === "ROLE_MANAGING_ADMIN"
                                }
                            />
                        }
                    >
                        <Route path="/manage" element={<Manage />} />
                    </Route>
                    <Route
                        element={
                            <CheckRights
                                rule={adminRole === "ROLE_SUPER_ADMIN"}
                            />
                        }
                    >
                        <Route path="/actions" element={<Actions />} />
                        <Route path="/admins" element={<Admins />} />
                        <Route path="/users" element={<UsersPage />} />
                        <Route path="/money-flow/*" element={<MoneyFlow />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
            <ConfirmWindow />
            <ToastContainer />
        </>
    );
}

function CheckRights({ rule = false }) {
    return rule ? <Outlet /> : <NotFound />;
}

export default App;
