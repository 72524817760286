import { useState } from "react";
import { ReactComponent as CloseSvg } from "./svg/close.svg";

export default function TransferFunds({ changeStateLayout }) {
    const [amount, setAmount] = useState("");
    const [to, setTo] = useState("");
    return (
        <div className="typical-layout">
            <div
                className="close-lar"
                onClick={() => changeStateLayout(false)}
            />
            <div className="confirm-block">
                <div className="title">
                    <span>Transfer funds</span>
                    <span
                        className="close"
                        onClick={() => changeStateLayout(false)}
                    >
                        <CloseSvg />
                    </span>
                </div>
                <div className="avail">
                    Available: <span>$10000</span>
                </div>
                <div className="inputs">
                    <label htmlFor="to">Transfer To:</label>
                    <input
                        type="text"
                        id="to"
                        value={to}
                        onChange={(target) => {
                            setTo(target.value);
                        }}
                        placeholder="User ID"
                    />
                </div>
                <div className="inputs">
                    <label htmlFor="amount">Enter amount:</label>
                    <input
                        type="number"
                        id="amount"
                        value={amount}
                        onChange={(target) => {
                            setAmount(target.value);
                        }}
                        placeholder="$ 0.00"
                    />
                </div>

                <div className="btns">
                    <button onClick={() => {}} className="confirm big-one">
                        <span>Confirm</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
