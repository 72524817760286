import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useRequestsMethods from "../hook/useRequestsMethods";
import { toast } from "react-toastify";
import { redux_cahngeUser } from "../Redux/userApi";

export default function Enter() {
    const [values, setValues] = useState({
        email: "",
        password: "",
    });

    const user = useSelector((state) => state.persistedReducer.user);
    const dispatch = useDispatch();
    const { login, getPersonData, proof2faPubl } = useRequestsMethods();

    const navigate = useNavigate();

    useEffect(() => {
        if (user.token && user.id) {
            navigate("/stata", { replace: true });
        }
        // eslint-disable-next-line
    }, [user]);

    return (
        <div className="admin">
            <div className="enter-container">
                <h2>Enter</h2>
                <input
                    type="text"
                    name="email"
                    placeholder="E-Mail"
                    value={values.email}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                />
                <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={values.password}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                />
                <button onClick={enter}>
                    <span> Enter</span>
                </button>
            </div>
        </div>
    );

    async function enter() {
        if (values.email.length < 3) return;
        if (values.password.length < 3) return;

        try {
            const { data: userTokens } = await login(values);
            console.log(userTokens);
            if (
                !userTokens?.personRole ||
                userTokens?.personRole === "ROLE_USER"
            )
                return toast.error("Check credentials");
            if (!userTokens.mfaEnabled) {
                try {
                    const { data: person } = await getPersonData(
                        userTokens.access_token
                    );
                    redux_cahngeUser(
                        dispatch,
                        { role: userTokens?.personRole, ...person },
                        userTokens.access_token,
                        userTokens.refresh_token
                    );
                } catch (er) {
                    console.log(er);
                    toast.error(er.response.data.message);
                }
                // load user
            } else {
                // toast.warning("2fa enabled, EWE NETY");
                const code = window.prompt("2fa code, please:");
                const { data: code_data } = await proof2faPubl(code);
                // console.log(code_data);
                const { data: person } = await getPersonData(
                    code_data.access_token
                );
                redux_cahngeUser(
                    dispatch,
                    { role: userTokens?.personRole, ...person },
                    code_data.access_token
                );
            }
        } catch (err) {
            console.log(err);
            toast.error(err.response.data);
        }
        // navigate("/stata");
    }

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            enter();
        }
    }

    function handleChange({ target }) {
        setValues((prev) => ({
            ...prev,
            [target.name]: target.value,
        }));
    }
}
