import { useEffect, useState } from "react";
// import useRequestsMethods from "../../../hook/useRequestsMethods";

export default function List({
    data,
    selectFromList,
    selectedItems,
    isFetching,
    openUserModal,
}) {
    // console.log(data);
    return (
        <div className="lst-holder">
            <table className="lst">
                <thead>
                    <tr>
                        <td>
                            <div
                                className={`own-check ${
                                    selectedItems.length === data.length &&
                                    data.length > 0
                                        ? "checked"
                                        : ""
                                }`}
                                onClick={() => {
                                    if (selectedItems.length === data.length) {
                                        selectFromList([], true, true);
                                    } else {
                                        selectFromList(data, true, true);
                                    }
                                }}
                            />
                        </td>
                        <td>uuid</td>
                        <td>Mail</td>
                        <td>Person ID</td>
                        <td>Wallet</td>
                        <td>Status</td>
                        <td>Sum</td>
                        <td>Date</td>
                    </tr>
                </thead>
                {/* <tbody>{isFetching ? renderLoading() : renderBody()}</tbody> */}
                <tbody className={isFetching ? "isFetching" : ""}>
                    {renderBody()}
                </tbody>
            </table>
        </div>
    );

    function renderBody() {
        return data.map((i) => {
            const [Y, M, D, h, m] = i.requestDateTime.map((d) =>
                d.toString().padStart(2, "0")
            );
            const date = `${h}:${m} ${D}.${M}.${Y}`;
            return (
                <RenderBody
                    data={i}
                    date={date}
                    key={i.uuid}
                    selectFromList={selectFromList}
                    selectedItems={selectedItems}
                    openUserModal={openUserModal}
                />
            );
        });
    }
}

function RenderBody({
    data,
    date,
    selectFromList = () => {},
    selectedItems,
    openUserModal,
}) {
    // const
    const [selected, setSelected] = useState(
        !!selectedItems?.find((i) => i.uuid === data.uuid)
    );
    // console.log(selectedItems);
    // console.log(data);
    useEffect(() => {
        setSelected(!!selectedItems?.find((i) => i.uuid === data.uuid));
    }, [selectedItems]);
    // useEffect(() => {
    //     if (selectedItems.length < 1) setSelected(false);
    // }, [selectedItems]);
    return (
        <tr className={data?.loading ? "loading_item" : ""}>
            <td className="clk">
                <div
                    className={`own-check ${selected ? "checked" : ""}`}
                    onClick={() => {
                        selectFromList(
                            {
                                uuid: data.uuid,
                                personId: data.personId,
                            },
                            !selected
                        );
                    }}
                />
            </td>
            <td>{data.uuid}</td>
            <td onClick={() => userInfo(data.personEmail)}>
                {data.personEmail}
            </td>
            <td>{data.personId}</td>
            <td>{data.walletAddress}</td>
            <td className={data.status}>{data.status}</td>
            <td>{data.sum}</td>
            <td>{date}</td>
        </tr>
    );

    function userInfo(mail) {
        openUserModal(mail);
    }
}
