import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { ReactComponent as OptionsArrowSvg } from "./svg/OptionArrow.svg";
import PickDate from "../../Layouts/DatePicker";

export default function RadialOptions({
    dataAll,
    chartDate,
    setChartDate,
    dataByDate = [],
    dataToShow = [
        {
            incomeOptionStatisticObject: [],
            withdrawalOptionStatisticObject: [],
        },
    ],
}) {
    const [allDataType, setAllDataType] = useState({
        label: "Withdrawal",
        value: "withdrawalOptionStatisticObject", //withdrawalOptionStatisticObject || incomeOptionStatisticObject
    });

    const [showDatePick, setShowDatePick] = useState(false);
    const dataTypeList = [
        {
            label: "Withdrawal",
            value: "withdrawalOptionStatisticObject",
        },
        {
            label: "Income",
            value: "incomeOptionStatisticObject",
        },
    ];
    console.log(dataToShow);

    const options = {
        title: {
            show: false,
        },
        tooltip: {
            trigger: "item",

            formatter(param) {
                return param.name + " (" + param.percent + "%)";
            },
        },
        legend: {
            orient: "verical",
            top: "10%",
            left: "5%",
            // data: legendData,
            selectedMode: true,
            textStyle: {
                color: "#ffff",
            },
            show: true,
        },
        series: [
            {
                name: "Name of",
                type: "pie",
                radius: ["40%", "75%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                // startAngle: 180,
                label: {
                    show: false,
                    // position: "center",
                    position: "outer",
                    alignTo: "labelLine",
                    bleedMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,
                    rich: {
                        time: {
                            fontSize: 10,
                            color: "#999",
                        },
                    },

                    formatter(param) {
                        return param.value;
                    },
                },
                labelLine: {
                    length: 15,
                    length2: 0,
                    maxSurfaceAngle: 80,
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: "18",
                        fontWeight: "bold",
                        color: "#f5f5f5",

                        // position: "outer",
                        // alignTo: "labelLine",
                        // bleedMargin: 5,
                        // edgeDistance: 10,
                        // lineHeight: 15,
                    },

                    shadowBlur: 10,
                    shadowOffsetX: 0,
                },
                labelLine: {
                    show: false,
                },
                selectedMode: "single",
                data: dataToShow[allDataType.value],
            },
        ],
    };
    return (
        <div>
            {showDatePick && (
                <PickDate
                    setShowDatePick={setShowDatePick}
                    setDatePick={setChartDate}
                    datePick={chartDate}
                />
            )}
            <div className="graph-settings">
                <h2>Options statistics</h2>

                {chartDate && (
                    <button
                        onClick={() => {
                            setChartDate(false);
                        }}
                    >
                        <span>Discard date</span>
                    </button>
                )}
                <button
                    onClick={() => {
                        setShowDatePick(true);
                    }}
                >
                    <span>Pick Date</span>
                </button>
                <div className="select-name">
                    <div className="headlist">
                        {allDataType.label} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {dataTypeList.map((i, n) => (
                            <li
                                onClick={() => {
                                    setAllDataType(i);
                                }}
                                className={chekClassForList(
                                    i.label,
                                    allDataType
                                )}
                                key={n}
                            >
                                {i.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="chrt">
                <ReactEcharts
                    option={options}
                    echarts={echarts}
                    style={{ height: "100%", width: "100%" }}
                    // onEvents={{
                    //     click: onChartClick,
                    //     legendselectchanged: onChartLegendselectchanged,
                    // }}
                />
            </div>
        </div>
    );
    // function onChartClick(param, echarts) {
    //     console.log(param, echarts);
    //     // setCount(count + 1);
    // }

    // function onChartLegendselectchanged(param, echarts) {
    //     console.log(param, echarts);
    // }
    function chekClassForList(type, data) {
        if (type === data.label) return "active";

        return "";
    }
}
