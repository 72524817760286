import { useEffect, useState } from "react";
import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import { ReactComponent as OptionsArrowSvg } from "./svg/OptionArrow.svg";

export default function Deposits({
    data,
    dateOptions,
    depostitsDataDate,
    changeDate,
    getOptionName,
}) {
    const [dataType, setDataType] = useState({
        label: "Contributions",
        value: "contribution",
    });
    const [activeOption, setActiveOption] = useState(false);
    const [optionList, setOptionList] = useState([]);
    const [dataForChart, setDataForChart] = useState({
        x: [],
        y: [],
    });

    useEffect(() => {
        if (!!activeOption && !!dataType) {
            //change and gen graph data
            getDataForChart();
        }
    }, [activeOption, dataType]);

    useEffect(() => {
        if (data) {
            getOptionsList();
        }
    }, [data, dataType, dateOptions]);

    const dataTypeList = [
        {
            label: "Contributions",
            value: "contribution",
        },
        {
            label: "Deposits",
            value: "deposits",
        },
        {
            label: "Outputs",
            value: "withdrawal",
        },
    ];
    const options = {
        title: {
            show: false,
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            show: false,
        },
        xAxis: {
            name: "Date",
            nameLocation: "center",
            nameGap: 30,
            type: "category",
            boundaryGap: false,
            // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            // data: data.x,
            data: dataForChart.x,
        },
        yAxis: {
            name: "Value",
            nameLocation: "center",
            nameGap: 30,
            type: "value",
            splitNumber: 4,
        },
        series: [
            {
                data: dataForChart.y,
                type: "line",
                smooth: true,
                areaStyle: {},
            },
        ],
    };
    return (
        <div>
            <div className="graph-settings">
                <h2>Deposit statistics</h2>
                <div className="select-name">
                    <div className="headlist">
                        {dataType.label} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {dataTypeList.map((i, n) => (
                            <li
                                onClick={() => {
                                    setDataType(i);
                                    setActiveOption(false);
                                    setOptionList([]);
                                }}
                                className={chekClassForList(i.label, dataType)}
                                key={n}
                            >
                                {i.label}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="select-name">
                    <div className="headlist">
                        {depostitsDataDate.label} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {dateOptions.map((i, n) => (
                            <li
                                onClick={() => {
                                    changeDate(i);
                                    setActiveOption(false);
                                    setOptionList([]);
                                }}
                                className={chekClassForList(
                                    i.label,
                                    depostitsDataDate
                                )}
                                key={n}
                            >
                                {i.label}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="select-name">
                    <div className="headlist">
                        {activeOption?.label ?? "---"} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {optionList.map((i, n) => (
                            <li
                                onClick={() => {
                                    setActiveOption(i);
                                }}
                                className={chekClassForList(
                                    i.label,
                                    activeOption
                                )}
                                key={n}
                            >
                                {i.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="chrt">
                <ReactEcharts
                    option={options}
                    echarts={echarts}
                    style={{ height: "100%", width: "100%" }}
                    // onEvents={+
                    //     {
                    //         // click: handleClick,
                    //     }
                    // }
                />
            </div>
        </div>
    );

    function getDataForChart() {
        if (activeOption.value === "all") {
            const fullData = data[dataType.value];
            console.log(fullData);
        } else {
            console.log(data[dataType.value][activeOption.value]);
            setDataForChart(data[dataType.value][activeOption.value]);
        }
    }
    function getOptionsList() {
        const optionsKeys = Object.keys(data[dataType.value] ?? []);
        const list = optionsKeys.map((i) => ({
            label: getOptionName(i),
            value: i,
        }));
        setActiveOption(list[0]);

        setOptionList(
            list?.length ? [{ label: "All", value: "all" }, ...list] : []
            // list
        );
        // .push({ label: "all", value: "all" })
    }
    function chekClassForList(type, checkBy = true) {
        if (type === checkBy.label) return "active";

        return "";
    }
}
