import React from "react";
import ReactDOM from "react-dom/client";
import { store, persistor } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import RequestsMethodsProvider from "./hoc/RequestsMethods";
import ConfrimProvider from "./hoc/ConfirmHoc";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./Scss/index.scss";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";

// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers";

import App from "./App";
import PopUpProvider from "./hoc/PopUp";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {/* <LocalizationProvider dateAdapter={ AdapterDayjs }>  */}
                <RequestsMethodsProvider>
                    <QueryClientProvider client={queryClient}>
                        <ConfrimProvider>
                            <PopUpProvider>
                                <App />
                            </PopUpProvider>
                        </ConfrimProvider>
                    </QueryClientProvider>
                </RequestsMethodsProvider>
                {/* </LocalizationProvider> */}
            </PersistGate>
        </Provider>
    </BrowserRouter>
);
