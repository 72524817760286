import { useQuery } from "@tanstack/react-query";
import useRequestsMethods from "../../../hook/useRequestsMethods";
import { ReactComponent as OptionsArrowSvg } from "./svg/OptionArrow.svg";
import {
    getAllTime,
    getMonth,
    getQuarter,
    getWeek,
    getYear,
} from "../../../utils/GetDate";
import { useEffect, useState } from "react";
import moment from "moment";
import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";

export default function Pool() {
    const { getPoolMoneyTime, getPoolGiveawayTime } = useRequestsMethods();

    // const {
    //     data: poolGiveawayTimeData,
    //     isError: poolGiveawayTimeError,
    //     isLoading: poolGiveawayTimeLoading,
    // } = useQuery({
    //     queryKey: ["PoolGiveawayTime"],
    //     queryFn: getPoolGiveawayTimeData,
    // });

    const dateOptions = [
        {
            label: "Week",
            value: getWeek(),
        },
        {
            label: "Month",
            value: getMonth(),
        },
        {
            label: "Quarter",
            value: getQuarter(),
        },
        {
            label: "Year",
            value: getYear(),
        },
        {
            label: "All Time",
            value: getAllTime(),
        },
    ];
    const [userDataDate, setUserDataDate] = useState(dateOptions[0]);

    const types = [
        {
            label: "Commission",
            value: "commissionObjects",
        },
        {
            label: "Reserve",
            value: "reserveObjects",
        },
    ];
    const [typeOfGrapg, setTypeOfGraph] = useState(types[0]);
    const {
        data: poolMoneyTimeData = null,
        isError: poolMoneyTimeError,
        isLoading: poolMoneyTimeLoading,
    } = useQuery({
        queryKey: ["PoolMoneyTime", userDataDate],
        queryFn: async () => {
            return getPoolMoneyTimeData(userDataDate.value);
        },
    });
    const [showData, setShowData] = useState({ x: [], y: [] });
    useEffect(() => {
        if (poolMoneyTimeData) {
            setShowData((prev) => {
                const data = poolMoneyTimeData[typeOfGrapg.value];
                const keysOfData = Object.keys(data);
                const show = keysOfData.reduce(
                    (acc, i) => {
                        const k = Object.keys(data[i]);
                        // const [date2, other2] = data[i];
                        // console.log(other);
                        const x = acc.x;
                        const y = acc.y;
                        k.map((ii) => {
                            if (ii === "date") {
                                x.push(
                                    moment(data[i][ii]).format("DD/MM/YYYY")
                                );
                            } else {
                                y.push(data[i][ii]);
                            }
                        });
                        return {
                            x,
                            y,
                        };
                    },
                    {
                        x: [],
                        y: [],
                    }
                );
                // console.log(show);
                return show;
            });
        }
    }, [poolMoneyTimeData, typeOfGrapg]);

    const options = {
        title: {
            show: false,
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            show: false,
        },
        xAxis: {
            name: "Date",
            nameLocation: "center",
            nameGap: 30,
            type: "category",
            boundaryGap: false,
            // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            data: showData.x,
        },
        yAxis: {
            name: "Value",
            nameLocation: "center",
            nameGap: 30,
            type: "value",
        },
        series: [
            {
                // data: [820, 300, 400, 932, 901, 934, 1290, 1330, 1320],
                data: showData.y,
                type: "line",
                smooth: true,
                areaStyle: {},
            },
        ],
    };

    // console.log(poolGiveawayTimeData);
    // console.log(userDataDate);
    console.log(poolMoneyTimeData);
    return (
        <div className="pool investments ">
            <div className="graph-settings">
                <h2>Money Time</h2>
                <div className="select-name">
                    <div className="headlist">
                        {userDataDate.label} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {dateOptions.map((i, n) => (
                            <li
                                onClick={() => {
                                    setUserDataDate(i);
                                }}
                                className={chekClassForList(i.label, true)}
                                key={n}
                            >
                                {i.label}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="select-name">
                    <div className="headlist">
                        {typeOfGrapg.label} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {types.map((i, n) => (
                            <li
                                onClick={() => {
                                    setTypeOfGraph(i);
                                }}
                                className={chekClassForList(i.label, true)}
                                key={n}
                            >
                                {i.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="chrt">
                <ReactEcharts
                    option={options}
                    echarts={echarts}
                    style={{ height: "100%", width: "100%" }}
                />
            </div>
        </div>
    );

    async function getPoolGiveawayTimeData({ from, to }) {
        const { data } = await getPoolGiveawayTime({
            // from: "2023-01-01",
            // to: "2024-03-22",
            from,
            to,
        });
        return data;
    }
    async function getPoolMoneyTimeData({ from, to }) {
        const { data } = await getPoolMoneyTime({
            // from: "2023-01-01",
            // to: "2024-03-22",
            from,
            to,
        });
        return data;
        const r = data.reduce(
            (acc, curr) => {
                let [year, mounth, day] = curr.date;
                if (mounth < 10) mounth = mounth.toString().padStart(2, `0`);
                if (day < 10) day = day.toString().padStart(2, `0`);
                return {
                    x: [...acc.x, `${day}/${mounth}/${year}`],
                    y: [...acc.y, curr.count],
                };
            },
            {
                x: [],
                y: [],
            }
        );
        return r;
    }

    function chekClassForList(type, users = true) {
        if (type === userDataDate.label) return "active";

        return "";
    }
}
