import axios from "axios";
import { createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../Redux/userApi";

export const RequestsMethodsContext = createContext(null);

function RequestsMethodsProvider({ children }) {
    // const [protectedUrl, setProtected] = useState(null);
    const user = useSelector((state) => state.persistedReducer.user);
    const dispatchFunc = useDispatch();

    const publicRequest = (() =>
        axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            // rejectUnauthorized: false,
            withCredentials: true,
        }))();

    const protectedRequest = (token = null) => {
        const api = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
            // rejectUnauthorized: true, // ssl off while develop
        });
        return api;
    };

    const protectedApi = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            // token: token,
            // rejectUnauthorized: false,
            Authorization: `Bearer ${user.token}`,
        },
        withCredentials: true,
        // rejectUnauthorized: true, // ssl off while develop
    });
    protectedApi.interceptors.response.use(
        (response) => response,
        (error) => {
            //handle error at top level
            console.log("handle error at top level protected api");
            console.log(error);
            if (error.response.data.message === "UNAUTHORIZED") {
                logoutUser(dispatchFunc, logout);
            }
            throw error;
        }
    );
    const login = (data) => {
        return publicRequest.post(`auth/signin`, {
            loginType: "ADMIN",
            ...data,
        });
    };
    const getPersonData = (token) => {
        console.log(token);
        return protectedRequest(token).get("person");
    };

    //person-admin-controller
    const getAdminsUsers = () => {
        return protectedApi.get("admin/crud/all");
    };
    const createAdminsUser = (data) => {
        return protectedApi.post("admin/crud/create", data);
    };
    const editAdminsRole = (data) => {
        console.log(data);
        return protectedApi.post("admin/crud/edit", data);
    };
    const deleteAdminsRole = (id) => {
        return protectedApi.get(`admin/crud/delete/${id}`);
    };

    //wallet-controller
    // const walletGetMoney = () => {
    //     return protectedApi.get(`wallet/get_money`);
    // };

    //transaction-view-controller
    // const getTransactions = () => {
    //     return protectedApi.get(`transaction`);
    // };

    //deposit-statistics-controller
    // +
    const getInvestUsersCountByPeriod = ({ from, to }) => {
        // Stat page
        // Users / Investments ++
        //Users
        return protectedApi.get(
            `admin/statistic/users_investments/users_count_by_period?from=${from}&to=${to}`
        );
    };
    const getInvestDepositStatistics = ({ from, to }) => {
        // Stat page
        // Users / Investments ++
        //Deposit statistics
        return protectedApi.get(
            `admin/statistic/users_investments/deposit_statistics?from=${from}&to=${to}`
        );
    };
    const getInvestCapacity = () => {
        // Stat page
        // Users / Investments ++
        //Capacity
        return protectedApi.get(`admin/statistic/users_investments/capacity`);
    };

    //pool-statistic-controller
    const getPoolMoneyTime = ({ from, to }) => {
        // Stat page
        // Pool
        //Money / Time
        return protectedApi.get(
            `admin/statistic/pool/money_time?from=${from}&to=${to}`
        );
    };
    const getPoolGiveawayTime = ({ from, to }) => {
        // Stat page
        // Pool
        //Giveaway / Time
        return protectedApi.get(
            `admin/statistic/pool/giveaway_time?from=${from}&to=${to}`
        );
    };

    //option-statistic-controller
    const getOptionByPeriod = ({ from, to }) => {
        // Stat page
        // Options
        //Options statistics
        return protectedApi.get(
            `admin/statistic/option/by_period?from=${from}&to=${to}`
        );
    };
    const getOptionAll = () => {
        // Stat page
        // Options
        //Options statistics
        return protectedApi.get(`admin/statistic/option/all`);
    };

    //viewing-by-users-controller
    const getDashboardUsersView = () => {
        // Stat page dashboard
        //Viewing by users
        //Viewing by investments
        return protectedApi.get(`admin/statistic/dashboard/users_view`);
    };
    //user-statistic-controller
    // Stat page
    //DONE
    const getDashboardReferrals = () => {
        //Users, Referrals
        return protectedApi.get(`admin/statistic/dashboard/referrals`);
    };
    const getDashboardActiveUser = () => {
        //Users, Active users
        return protectedApi.get(`admin/statistic/dashboard/active_users`);
    };
    const getDashboardOnlineCount = () => {
        //Users, Online
        return protectedApi.get(`admin/statistic/dashboard/online_count`);
    };
    const getDashboardRefBal = () => {
        //RefBal
        return protectedApi.get(`admin/statistic/dashboard/referral_balance`);
    };
    const getDashboardRefFundBal = () => {
        //Ref fund Bal
        return protectedApi.get(
            `admin/statistic/dashboard/referral_fund_balance`
        );
    };
    const getDashboardInvestFundBal = () => {
        //inves fund Bal
        return protectedApi.get(
            `admin/statistic/dashboard/invest_fund_balance`
        );
    };

    //users-balance-controller
    //DASBOARD - BALANCE
    const getDashboardUsersWithdrawBalance = () => {
        return protectedApi.get(
            `admin/statistic/dashboard/users_withdraw_balance`
        );
    };
    const getDashboardUsersIncomeBalance = () => {
        return protectedApi.get(
            `admin/statistic/dashboard/users_income_balance`
        );
    };
    const getDashboardUsersBalance = () => {
        return protectedApi.get(`admin/statistic/dashboard/users_balance`);
    };
    const getDashboardUsersCommissionBalance = () => {
        return protectedApi.get(
            `admin/statistic/dashboard/user_commission_balance`
        );
    };
    //buffer-controller
    //DONE
    const getDashboardPoolSize = () => {
        // Stat page
        //Buffer, Reserve size
        // +
        return protectedApi.get(`admin/statistic/dashboard/pool_size`);
    };
    const getDashboardPoolReserve = () => {
        // Stat page
        //Buffer, Pool size
        // +
        return protectedApi.get(`admin/statistic/dashboard/pool_reserve`);
    };

    //admin-pool-controller
    //DONE
    const getDashboardPoolCapacity = () => {
        // Stat page
        //Pool, Pool capacity
        // +
        return protectedApi.get(`admin/statistic/dashboard/pool_capacity`);
    };
    const getDashboardLastGiveaway = () => {
        // Stat pagegetActionAll
        //Pool, Closing the last giveaway - return in sec number
        // +
        return protectedApi.get(`admin/statistic/dashboard/last_giveaway`);
    };
    const getDashboardLastGiveawayCompleted = () => {
        return protectedApi.get(
            `admin/statistic/dashboard/last_giveaway_completed`
        );
    };

    //viewing-by-investment-controller
    //
    const getDashboardInvestmentsView = () => {
        //Viewing by investments - Dashboard, stat
        return protectedApi.get(`admin/statistic/dashboard/investments_view`);
    };
    //action-controller
    const getActionAll = ({ date = false, name = false }) => {
        //Dobavil - ne pon kak rabotat =( - nyjno smotret logic na beke
        // ?date=${date}&name=${name}
        let params = new URLSearchParams();
        if (date) {
            params.append("dateFrom", date.dateFrom);
            params.append("dateTo", date.dateTo);
        }
        if (name) params.append("username", name);
        params = params.toString();
        // return async () => {
        //     return 5;
        //     return new Error("own error check");
        // };
        return protectedApi.get(
            `admin/action/all${params ? "?" + params : ""}`
        );
    };

    //admin-manage-controller
    // Done
    const getCurrent_output_time_threshold = () => {
        return protectedApi.get(`admin/manage/current_output_time_threshold`);
    };
    const getCurrent_pool_capacity = () => {
        return protectedApi.get(`admin/manage/current_pool_capacity`);
    };
    const get_the_filling_ratio = () => {
        return protectedApi.get(`admin/manage/get_the_filling_ratio`);
    };
    const getMax_pool_capacity = () => {
        return protectedApi.get(`admin/manage/max_pool_capacity`);
    };

    const postSave_Max_pool_capacity = (
        data = { capConst1: 0, capConst2: 0 }
    ) => {
        return protectedApi.post(`admin/manage/save_max_pool_capacity`, data);
    };
    const postSave_time_threshold = (time) => {
        return protectedApi.post(
            `admin/manage/save_output_time_threshold?time=${time}`
        );
    };
    const postSave_the_filling_ratio = (data) => {
        return protectedApi.post(`admin/manage/save_the_filling_ratio`, data);
    };

    //commission-percent-controller
    // !!! ERRORS DB =)
    const getCommission_percent = () => {
        return protectedApi.get(`admin/manage/commission_percent`);
    };
    const postCommission_percent = (data) => {
        return protectedApi.post(`admin/manage/commission_percent`, data);
    };

    //pool-controller
    // DONE
    const postManual_pool = (
        data = {
            poolReserveSum: 0,
        }
    ) => {
        return protectedApi.post(`admin/manage/manual_pool`, data);
    };
    const postAutomatic_pool = (
        data = {
            maxTimeRange: 0,
            autoEnable: true,
        }
    ) => {
        // ERROR
        return protectedApi.post(`admin/manage/automatic_pool`, data);
    };

    //option-controller
    // DONE ERRORS
    const postUpdate_price_option = (
        data = {
            paymentName: "O4",
            value: 0,
        }
    ) => {
        // P7 P1 P2 O1-O10
        return protectedApi.post(`admin/manage/update_price_option`, data);
    };
    const postUpdate_filling_rate = (
        data = {
            optionNumber: 0,
            nextFillingRate: 0,
        }
    ) => {
        return protectedApi.post(`admin/manage/update_filling_rate`, data);
    };
    const postBoost = (
        data = {
            optionNumber: 0,
            boost: true,
        }
    ) => {
        return protectedApi.post(`admin/manage/boost`, data);
    };

    const getWithdList = ({
        page = 0,
        size = 20,
        sort = false | "",
        status = "PENDING" | "APPROVED" | "DECLINED",
        userId = null,
    }) => {
        // console.log(size);
        let params = new URLSearchParams();
        if (page) params.append("page", page);
        if (size) params.append("size", size);
        if (sort) params.append("sort", sort);
        if (status) params.append("status", status);
        if (userId) params.append("userId", userId);
        params = params.toString();
        // ?page=0&size=1&sort=string&status=PENDING
        return protectedApi.get(`withdrawal${params ? "?" + params : ""}`);
    };
    const changeWithdStatus = ({
        uuid = "",
        personId = 0,
        status = "APPROVED" | "DECLINED",
    }) => {
        // ?page=0&size=1&sort=string&status=PENDING
        return protectedApi.put(`withdrawal`, {
            uuid,
            personId,
            status,
        });
    };

    // const getUserInfo = (email = "") => {
    //     //dateRange
    //     return protectedApi.post(`admin/crud/users`, {
    //         email,
    //     });
    // };  OLD !!!!!

    //balance-controller
    const updRefBalance = (userId = 0, amount = 0, comment = "no comment") => {
        //dateRange
        return protectedApi.put(`balance/referral?userId=${userId}`, {
            amount,
            comment,
        });
    };
    const updBaseBalance = (userId = 0, amount = 0, comment = "no comment") => {
        //dateRange
        return protectedApi.put(`balance/base?userId=${userId}`, {
            amount,
            comment,
        });
    };
    const banUser = (userId = 0) => {
        //dateRange
        return protectedApi.put(`admin/crud/users/${userId}/ban`);
    };

    //user-controller
    const getUserInfo = (date = "", email = "") => {
        //dateRange
        let data = {};
        let params = new URLSearchParams();
        if (date) params.append("dateRange", date);
        params = params.toString();
        if (email) data.email = email;
        return protectedApi.post(`users${params ? "?" + params : ""}`, data);
    };
    const getUsersList = (
        data = [
            // {
            //     measure: "",
            //     subCategory: "",
            //     aggregationOperation: "",
            //     limitOperation: "",
            //     limitValue: 0,
            // },
            {
                measure: "INCOME",
                subCategory: "options",
                aggregationOperation: "SUM",
                limitOperation: "TO",
                limitValue: "500.00",
            },
            {
                measure: "WITHDRAWAL",
                subCategory: "options",
                aggregationOperation: "SUM",
                limitOperation: "TO",
                limitValue: "1000",
            },
            // {
            //   "measure": "PAYMENTS",
            //   "subCategory": "string",
            //   "aggregationOperation": "SUM",
            //   "limitOperation": "FROM",
            //   "limitValue": 0
            // }
        ]
    ) => {
        //dateRange
        // let data = {};
        // let params = new URLSearchParams();
        // if (date) params.append("dateRange", date);
        // params = params.toString();
        // if (email) data.email = email;
        console.log(data);
        return protectedApi.post(`users/search`, data);
    };

    const logout = () => {
        return protectedApi.post(`auth/logout`);
    };
    const proof2faPubl = (code) => {
        return publicRequest.post(`auth/verify_code?code=${code}`);
    };

    /*
    1. users-balance-controller:
        dasboard - Balance
    
    2. referral-admin-controller
        // jdem
    
    3. commission-percent-controller
        Page: Manage
        Changing commission
        300 - get
    
    4.  pool-controller
        Page: Manage
        Automatically fill pool from reserve
        pool - input na time + 2 knopki a otdelno nijhe input na manual pool  + save btn
        def na time  = 60 sec
        !!!errr!!!

    */
    const val = {
        login,
        proof2faPubl,
        getPersonData,
        getAdminsUsers,
        createAdminsUser,
        editAdminsRole,
        deleteAdminsRole,

        //deposit-statistics-controller
        getInvestUsersCountByPeriod,
        getInvestDepositStatistics,
        getInvestCapacity,

        //pool-statistic-controller
        getPoolMoneyTime,
        getPoolGiveawayTime,
        //option-statistic-controller
        getOptionByPeriod,
        getOptionAll,

        //viewing-by-users-controller
        getDashboardUsersView,

        // user-statistic-controller
        getDashboardReferrals,
        getDashboardActiveUser,
        getDashboardOnlineCount,
        getDashboardRefBal,
        getDashboardRefFundBal,
        getDashboardInvestFundBal,

        // users-balance-controller
        getDashboardUsersWithdrawBalance,
        getDashboardUsersIncomeBalance,
        getDashboardUsersBalance,
        getDashboardUsersCommissionBalance,

        // buffer-controller
        getDashboardPoolSize,
        getDashboardPoolReserve,

        // admin-pool-controller
        getDashboardPoolCapacity,
        getDashboardLastGiveaway,
        getDashboardLastGiveawayCompleted,

        // viewing-by-investment-controller
        getDashboardInvestmentsView,

        //admin-manage-controller
        getCurrent_output_time_threshold,
        getCurrent_pool_capacity,
        get_the_filling_ratio,
        getMax_pool_capacity,
        postSave_Max_pool_capacity,
        postSave_time_threshold,
        postSave_the_filling_ratio,

        // pool-controller
        postManual_pool,
        postAutomatic_pool,

        // option-controller
        postUpdate_price_option,
        postUpdate_filling_rate,
        postBoost,
        // commission-percent-controller
        getCommission_percent,
        postCommission_percent,
        // action-controller
        //Actions page jdat` date & name change
        getActionAll,

        logout,

        //balance
        getWithdList,
        changeWithdStatus,

        //user info
        // getUserInfo,

        //balance-controller
        updRefBalance,
        updBaseBalance,
        banUser,
        //user-controller
        getUserInfo,
        getUsersList,
    };
    return (
        <RequestsMethodsContext.Provider value={val}>
            {children}
        </RequestsMethodsContext.Provider>
    );
}

export default RequestsMethodsProvider;
