import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();
    return (
        <>
            <h1>Not Found</h1>
            <div className="btns" style={{ display: "flex", gap: "10px" }}>
                <button
                    onClick={() => {
                        navigate(-1, { replace: true });
                    }}
                >
                    <span>Go back</span>
                </button>
                <button
                    onClick={() => {
                        navigate("/", { replace: true });
                    }}
                >
                    <span>Go Home</span>
                </button>
            </div>
        </>
    );
}
