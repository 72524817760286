import { Route, Routes } from "react-router-dom";
import Dashboard from "../Components/Statistics/Dashboard";
import MiniHead from "../Components/Statistics/MiniHead";
import Investments from "../Components/Statistics/Investments";
import Pool from "../Components/Statistics/Pool";
import Options from "../Components/Statistics/Options";

export default function Statistics() {
    return (
        <div className="page-block statistics">
            <div className="width-content">
                <MiniHead />
                <Routes>
                    <Route
                        index
                        element={<Dashboard getQueryData={getQueryData} />}
                    />
                    <Route
                        path="investments"
                        element={<Investments getQueryData={getQueryData} />}
                    />
                    <Route path="options" element={<Options />} />
                    <Route path="pool" element={<Pool />} />
                </Routes>
            </div>
        </div>
    );

    function getQueryData(mainKey = "", data = []) {
        return {
            queries: data.map((q) => ({
                queryKey: [mainKey, q.key],
                queryFn: q.fn,
            })),
            combine: (results) => {
                return {
                    data: results.map((result) =>
                        result.isSuccess ? result.data.data : false
                    ),
                    pending: results.some((result) => result.isPending),
                    error: results.some((result) => result.isError),
                    errors: results.map((result) =>
                        result.isError ? result.error : false
                    ),
                };
            },
        };
    }
}
