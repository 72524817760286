import { useEffect, useState } from "react";
import { ReactComponent as DatePickSvg } from "./svg/datePick.svg";
import ListItem from "../Components/Actions/ListItem";
import useRequestsMethods from "../hook/useRequestsMethods";
import { useQuery } from "@tanstack/react-query";
import { useDebounceEffect } from "../hook/useDebounceEffect";
import PickDate from "../Components/Layouts/DatePicker";
import moment from "moment";

const tryData = {
    photo: null,
    text1: "Test Name",
    text2: "Test SMTH",
    text3: "test test",
};
export default function Actions() {
    // tyt nado debounce na name
    const [nameFilter, setNameFilter] = useState("");
    const [showDatePick, setShowDatePick] = useState(false);

    const [testDate, setTestDate] = useState(false);

    const [nameForSearch, setNameForSearch] = useState(false);
    const [dateForSearch, setDateForSearch] = useState(false);

    const { getActionAll } = useRequestsMethods();

    useDebounceEffect(
        () => {
            setNameForSearch(nameFilter ? nameFilter : false);
        },
        800,
        [nameFilter]
    );
    useEffect(() => {
        if (testDate) {
            setDateForSearch({
                dateFrom: moment(testDate.startDate).format("YYYY-MM-DD"),
                dateTo: moment(testDate.endDate).format("YYYY-MM-DD"),
            });
        } else {
            setDateForSearch(false);
        }
    }, [testDate]);
    // useDebounceEffect(()=>{
    //     setNameForSearch(nameFilter ? nameFilter : false);
    // },800,[nameFilter])

    const {
        data: actionsList = [], //Changing the filling ratio
        isError: ErrorActionsList,
        isLoading: LoadActionsList,
    } = useQuery({
        queryKey: ["ActionsAll", nameForSearch, dateForSearch],
        queryFn: async () => {
            const { data } = await getActionAll({
                name: nameForSearch,
                date: dateForSearch,
            });
            return data;
        },
        retry: false,
    });

    //date 2024-03-05
    // {date: "", name: "" }
    // console.log(testDate);
    // console.log(dateForSearch);

    // console.log(ErrorActionsList);
    return (
        <div className="page-block actions">
            {showDatePick && (
                <PickDate
                    setShowDatePick={setShowDatePick}
                    setDatePick={setTestDate}
                    datePick={testDate}
                />
            )}
            <div className="width-content">
                <div className="head">
                    <div className="title">Actions</div>
                    <div className="state">
                        <div className="inputs">
                            <label htmlFor="name">Enter Name</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                className={!!nameForSearch ? "active" : ""}
                                placeholder=""
                                value={nameFilter}
                                onChange={({ target }) =>
                                    setNameFilter(target.value)
                                }
                            />
                        </div>
                        <div
                            className={`datePick ${!!testDate ? "active" : ""}`}
                            onClick={() => {
                                setShowDatePick(true);
                            }}
                        >
                            <DatePickSvg />
                        </div>
                    </div>
                </div>
                {ErrorActionsList && <h1>Error fetching data</h1>}
                <div className="actions-container">
                    {renderData(actionsList)}
                </div>
            </div>
        </div>
    );

    function renderData(data) {
        return [...data].reverse().map((item, index) => {
            return (
                <ListItem key={index} item={item} selectName={setNameFilter} />
            );
        });
    }
}
