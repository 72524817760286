import { useState } from "react";
import { ReactComponent as CapacityPoolSvg } from "../svg/capacityOutput.svg";
import { ReactComponent as OptionsArrowSvg } from "../svg/OptionArrow.svg";
import useRequestsMethods from "../../../hook/useRequestsMethods";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function Right() {
    const [aoutoPoolCheckboxState, setAoutoPoolCheckboxState] = useState(true);

    const { getCommission_percent, postCommission_percent } =
        useRequestsMethods();
    // const queryClient = useQueryClient();
    // const {
    //     data: CommissionPrcnt = null, //Changing the filling ratio
    //     isError: ErrorFillingRatio,
    //     isLoading: LoadFillingRatio,
    // } = useQuery({
    //     queryKey: ["CommissionPrcnt"],
    //     queryFn: getCommission_percent,
    //     retry: false,
    // });
    return (
        <div className="right">
            {/* <div className="big-block bg-block">
                <h3>
                    <span>Automatically fill pool from reserve</span>
                    <div
                        className={`checkbox ${
                            aoutoPoolCheckboxState ? "active" : ""
                        }`}
                        onClick={() => {
                            setAoutoPoolCheckboxState((prev) => !prev);
                        }}
                    />
                </h3>
                <div className="row">
                    <div className="inputs">
                        <label htmlFor="autoPoolAmount">Enter Amount</label>
                        <input type="text" placeholder="000" />
                    </div>
                    <div className="timer">00:00:00</div>
                </div>
                <button>
                    <span>Fill pull</span>
                </button>
            </div> */}

            {/* <div className="big-block bg-block">
                <h3>
                    <span>Changing the option multiplier</span>
                    <div className="select-name">
                        <div className="headlist">
                            Andromeda
                            <OptionsArrowSvg />
                        </div>
                        <ul className="list">
                            <li className={chekClassForList("all")}>---</li>
                            <li className={chekClassForList("year")}> ---</li>
                            <li className={chekClassForList("quarter")}>---</li>
                        </ul>
                    </div>
                </h3>
                <div className="row">
                    <div className="inputs">
                        <label htmlFor="optionMultiplierPrcnt">Enter %</label>
                        <input
                            type="text"
                            placeholder="%"
                            id="optionMultiplierPrcnt"
                        />
                    </div>
                    <div className="timer">300%</div>
                </div>
            </div> */}
            <PoolController />

            {/* <div className="big-block bg-block">
                <h2>
                    <CapacityPoolSvg /> Changing commission (Fee/Tax)
                </h2>
                <div className="row">
                    <div className="inputs">
                        <label htmlFor="сhangingFee">Enter %</label>
                        <input type="text" placeholder="%" id="сhangingFee" />
                    </div>
                    <div className="timer">300%</div>
                </div>
            </div>
            <button>
                <span>Confirm</span>
            </button> */}
        </div>
    );
    function chekClassForList(type, check = "") {
        if (type === check) return "active";
        return "";
    }
}

export function PoolController() {
    const [maxTimeRange, setMaxTimeRange] = useState("");
    const [poolReserveSum, setPoolReserveSum] = useState("");

    const { postManual_pool, postAutomatic_pool } = useRequestsMethods();

    const changeAutomatic_pool = useMutation({
        mutationFn: postAutomatic_pool,
    });
    const changeManualPool = useMutation({
        mutationFn: postManual_pool,
    });

    return (
        <div className="big-block bg-block">
            <h3>
                <span>Automatically fill pool from reserve</span>
            </h3>
            <div className="row">
                <div className="inputs">
                    <label htmlFor="autoPoolAmount">
                        Enter Max Time Range (s)
                    </label>
                    <input
                        type="text"
                        placeholder="60"
                        value={maxTimeRange}
                        onChange={({ target }) => {
                            const input = target.value.replace(/\D/g, "");
                            setMaxTimeRange(input);
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <button
                    onClick={() => {
                        goAutomatic(true);
                    }}
                >
                    <span>On</span>
                </button>
                <button
                    className="dec"
                    onClick={() => {
                        goAutomatic(false);
                    }}
                >
                    Off
                </button>
            </div>
            <div className="row">
                <div className="inputs">
                    <label htmlFor="autoPoolAmount">
                        Enter pool Reserve Sum
                    </label>
                    <input
                        type="text"
                        placeholder="000"
                        value={poolReserveSum}
                        onChange={({ target }) => {
                            const input = target.value.replace(/\D/g, "");
                            setPoolReserveSum(input);
                        }}
                    />
                </div>
            </div>
            <button onClick={goManual}>
                <span>Fill pull</span>
            </button>
        </div>
    );

    function goManual() {
        if (!poolReserveSum) return;
        changeManualPool.mutate(
            {
                poolReserveSum: poolReserveSum,
            },
            {
                onSuccess: (data) => {
                    console.log(data);
                    toast.success(`Manual pool seted to ${poolReserveSum} `);
                    setPoolReserveSum("");
                },
                onError: (error) => {
                    console.error(error);
                    const msg = error.response.data;
                    toast.error(msg);
                },
            }
        );
    }

    function goAutomatic(st) {
        const autoTime = maxTimeRange ? maxTimeRange : 60;
        changeAutomatic_pool.mutate(
            {
                maxTimeRange: autoTime,
                autoEnable: st,
            },
            {
                onSuccess: (data) => {
                    console.log(data);
                    setMaxTimeRange("");
                    toast.success(
                        `Automatic pool is ${
                            st ? `enabled wtih ${autoTime}s` : "disabled"
                        } `
                    );
                },
                onError: (error) => {
                    console.error(error);
                    const msg = error.response.data;
                    toast.error(msg);
                },
            }
        );
    }
}
