import { useState } from "react";
import BigBlock from "../Components/Admins/BigBlock";
import { ReactComponent as PlusSvg } from "./svg/plus.svg";
import { ReactComponent as CloseSvg } from "./svg/close.svg";
import useRequestsMethods from "../hook/useRequestsMethods";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export default function Admins() {
    const [addLayer, setAddLayer] = useState(false);
    const {
        getAdminsUsers,
        createAdminsUser,
        deleteAdminsRole,
        editAdminsRole,
    } = useRequestsMethods();
    const queryClient = useQueryClient();
    // hotrehamlo@gufum.com

    const {
        data: { data: userList = null } = { data: null },
        isError,
        isLoading,
    } = useQuery({
        queryKey: ["admins_list"],
        queryFn: getAdminsUsers,
    });

    const addAdmin = useMutation({
        mutationFn: createAdminsUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["admins_list"] });
        },
    });
    const changeRole = useMutation({
        mutationFn: editAdminsRole,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["admins_list"] });
        },
    });
    const deleteUser = useMutation({
        mutationFn: deleteAdminsRole,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["admins_list"] });
        },
    });

    // console.log(userList);

    const renderBlocks = [
        {
            info: {
                title: "Super admins",
                description: "Description",
            },
            role: "ROLE_SUPER_ADMIN",
        },
        {
            info: {
                title: "Managing admins",
                description: "Description",
            },
            role: "ROLE_MANAGING_ADMIN",
        },
        {
            info: {
                title: "Admins",
                description: "Description",
            },
            role: "ROLE_ADMIN",
        },
    ];
    // if()
    return (
        <div className="page-block admins">
            <div className="width-content">
                <div className="head">
                    <div className="title">
                        <div className="top">Team members</div>
                        <div className="bot">
                            Manage your team members and their account
                            permissions here
                        </div>
                    </div>
                    <button
                        onClick={() => {
                            setAddLayer(true);
                        }}
                    >
                        <span>
                            Add new member <PlusSvg />
                        </span>
                    </button>
                </div>

                {isLoading || !userList
                    ? "Loading..."
                    : renderBigBlock(renderBlocks, userList)}
                {addLayer && (
                    <AddLayout
                        changeLayoutState={setAddLayer}
                        addAdmin={addAdmin}
                    />
                )}
            </div>
        </div>
    );

    function renderBigBlock(data, list = []) {
        // console.log(list);
        return data.map((item, index) => {
            const usersData = getUsers(item.role, list);
            return (
                <BigBlock
                    info={item.info}
                    usersData={usersData}
                    key={index}
                    changeRole={changeRole}
                    deleteUser={deleteUser}
                />
            );
        });
    }

    function getUsers(role, userList) {
        return userList
            .map((userData) => {
                // console.log(userData);
                if (userData.role === role) {
                    return {
                        name: userData.personName,
                        mail: userData.email,
                        date: userData?.creationDate || "00.00.2000",
                        img: userData?.personImage || null,
                        role: role,
                        lastActive: userData?.lastActiveDate || "00.00.00",
                        id: userData.id,
                    };
                }
            })
            .filter((i) => i);
    }
}

function AddLayout({ changeLayoutState, addAdmin }) {
    const [role, setRole] = useState("ROLE_ADMIN");
    const [mail, setMail] = useState("");
    // console.log(role);
    return (
        <div className="typical-layout">
            <div
                className="close-lar"
                onClick={() => changeLayoutState(false)}
            />
            <div className="confirm-block">
                <div className="title">
                    <span>Add new admin</span>
                    <span
                        className="close"
                        onClick={() => changeLayoutState(false)}
                    >
                        <CloseSvg />
                    </span>
                </div>
                <div className="inputs">
                    <label htmlFor="mail">Enter email:</label>
                    <input
                        type="text"
                        id="mail"
                        value={mail}
                        onChange={({ target }) => {
                            setMail(target.value);
                        }}
                        placeholder="user@gmail.com"
                    />
                </div>
                <h2>Select role:</h2>
                <div className="radio-list">
                    <div className="inp">
                        <input
                            type="radio"
                            name="role"
                            id="ROLE_ADMIN"
                            onChange={manualChange}
                        />
                        <label htmlFor="ROLE_ADMIN">
                            <div
                                className={`self-radio ${
                                    role === "ROLE_ADMIN" ? "checked" : ""
                                }`}
                            />
                            <span>Admin</span>
                        </label>
                    </div>
                    <div className="inp">
                        <input
                            type="radio"
                            name="role"
                            id="ROLE_MANAGING_ADMIN"
                            onChange={manualChange}
                        />
                        <label htmlFor="ROLE_MANAGING_ADMIN">
                            <div
                                className={`self-radio ${
                                    role === "ROLE_MANAGING_ADMIN"
                                        ? "checked"
                                        : ""
                                }`}
                            />
                            <span>Managing admin</span>
                        </label>
                    </div>
                    {/* <div className="inp">
                        <input
                            type="radio"
                            name="role"
                            id="ROLE_SUPER_ADMIN"
                            onChange={manualChange}
                        />
                        <label htmlFor="ROLE_SUPER_ADMIN">
                            <div
                                className={`self-radio ${
                                    role === "ROLE_SUPER_ADMIN" ? "checked" : ""
                                }`}
                            />
                            <span>Super admin</span>
                        </label>
                    </div> */}
                </div>
                <div className="btns">
                    <button
                        onClick={() => {
                            changeLayoutState(false);
                        }}
                        className="cancel"
                    >
                        <span>Discard</span>
                    </button>
                    <button onClick={handleAddAdmin} className="confirm">
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>
    );

    function handleAddAdmin() {
        // console.log(mail, role);
        addAdmin.mutate(
            {
                email: mail,
                role: role,
            },
            {
                onSuccess: (data) => {
                    console.log(data);
                    changeLayoutState(false);
                    toast.success("Добавленно");
                },
                onError: (error) => {
                    console.error(error);
                    const msg = error.response.data;
                    toast.error(msg);
                },
            }
        );
        // addAdmin
    }

    function manualChange({ target }) {
        setRole(target.id);
    }
}
