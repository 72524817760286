import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import TransferFunds from "./TransferFunds";
import { useSelector } from "react-redux";

export default function Layout() {
    const [transferLayout, setTransferLayout] = useState(false);

    const user = useSelector((state) => state.persistedReducer.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user.token || !user.id) {
            navigate("/", { replace: true });
        }
        // eslint-disable-next-line
    }, [user]);

    if (!user.token) return "";
    return (
        <div className="position">
            <Sidebar
                openTransfer={setTransferLayout}
                transferCheck={transferLayout}
            />

            <div className="holder">
                <Header />
                <Outlet />

                {transferLayout && (
                    <TransferFunds changeStateLayout={setTransferLayout} />
                )}
            </div>
        </div>
    );
}
