import { useState, useEffect, useRef } from "react";
import { ReactComponent as CapacityOutputSvg } from "../svg/capacityOutput.svg";
import { ReactComponent as CapacityPoolSvg } from "../svg/capacityOutput.svg";
import useRequestsMethods from "../../../hook/useRequestsMethods";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDebounceEffect } from "../../../hook/useDebounceEffect";
import { toast } from "react-toastify";

export default function CapacityBlock() {
    const [newTimeThreshold, setTimeThreshold] = useState("");
    const {
        getCurrent_output_time_threshold,
        getCurrent_pool_capacity,
        getMax_pool_capacity,
        postSave_Max_pool_capacity,
        postSave_time_threshold,
        getDashboardLastGiveaway,
    } = useRequestsMethods();
    const queryClient = useQueryClient();
    const {
        data: { data: OutputTime } = { data: null },
        isError: ErrorOutputTime,
        isLoading: LoadOutputTime,
    } = useQuery({
        queryKey: ["OutputTime"],
        queryFn: getCurrent_output_time_threshold,
    });
    const {
        data: { data: PoolCapacity } = { data: null },
        isError: ErrorPoolCapacity,
        isLoading: LoadPoolCapacity,
    } = useQuery({
        queryKey: ["PoolCapacity"],
        queryFn: getCurrent_pool_capacity,
    });
    const {
        data: { data: MaxPoolCapacity } = { data: null },
        isError: ErrorMaxPoolCapacity,
        isLoading: LoadMaxPoolCapacity,
    } = useQuery({
        queryKey: ["PoolCapacity", "MaxPoolCapacity"],
        queryFn: getMax_pool_capacity,
    });
    const {
        data: { data: ClosingLastGiveaway } = { data: null },
        isError: ErrorClosingLastGiveaway,
        isLoading: LoadClosingLastGiveaway,
    } = useQuery({
        queryKey: ["ClosingLastGiveaway"],
        queryFn: getDashboardLastGiveaway,
    });

    const changePoolCapacity = useMutation({
        mutationFn: postSave_Max_pool_capacity,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["PoolCapacity"], // zametka
            });
        },
    });
    const changeTimeThreshold = useMutation({
        mutationFn: postSave_time_threshold,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["OutputTime"] });
            toast.success(
                `Output time threshold changed to ${newTimeThreshold}`
            );
        },
    });

    if (
        ErrorPoolCapacity ||
        ErrorOutputTime ||
        ErrorMaxPoolCapacity ||
        ErrorClosingLastGiveaway
    )
        return "Error";

    console.log(ClosingLastGiveaway);
    return (
        <div className="big-block bg-block">
            <h2>
                <CapacityOutputSvg />
                Output time threshold
            </h2>
            <div className="mini-block">
                <div className="inputs">
                    <label htmlFor="time">Enter time</label>
                    <input
                        type="text"
                        id="time"
                        placeholder="00:00:00"
                        value={newTimeThreshold}
                        onChange={({ target }) => {
                            const input = target.value.replace(/\D/g, ""); // Удаление всех символов, кроме цифр
                            setTimeThreshold(input);
                        }}
                    />
                </div>
                <div className="timer">
                    {LoadOutputTime ? "00:00:00" : `${OutputTime}s.`}
                </div>
                <div className="slider-timer">
                    {LoadOutputTime ? "" : renderLevels(OutputTime)}
                </div>
                <div className="timer">
                    {LoadClosingLastGiveaway
                        ? "00"
                        : `${ClosingLastGiveaway}s.`}
                </div>
            </div>
            <button
                style={{
                    marginTop: "10px",
                }}
                onClick={() => {
                    if (newTimeThreshold) {
                        changeTimeThreshold.mutate(newTimeThreshold, {
                            onError: (error) => {
                                console.error(error);
                                const msg = error.response.data;
                                toast.error(msg);
                            },
                        });

                        setTimeThreshold("");
                    }
                }}
            >
                Save
            </button>

            <h2>
                <CapacityPoolSvg /> Max pool capacity
            </h2>

            {LoadMaxPoolCapacity ? (
                "Loading"
            ) : (
                <MaxPoolCapacityBlock
                    data={MaxPoolCapacity}
                    changePoolCapacity={changePoolCapacity}
                />
            )}
            <h2>
                <CapacityPoolSvg /> Current pool capacity
            </h2>
            <div className="mini-block">
                {LoadPoolCapacity
                    ? "Loading"
                    : renderCapacityBlock(PoolCapacity)}
            </div>
        </div>
    );

    function renderLevels(seconds = 1 * 60 * 60) {
        // console.log(seconds);
        const maxLvls = 9;
        // const step = 4600 // seconds = 1.3 hours
        const max = 43200; // 12 hours
        const step = max / maxLvls;
        let renderLevels = maxLvls - Math.floor(seconds / step);

        if (renderLevels < 1) renderLevels = 1;
        if (renderLevels > maxLvls) renderLevels = maxLvls;

        return Array(maxLvls)
            .fill(true, 0)
            .map((i, n) => {
                return (
                    <div
                        className={`lvl ${n < renderLevels ? "show" : ""}`}
                        key={`lvl_${n}`}
                    />
                );
            });
    }
    function renderCapacityBlock(
        PoolCapacity = {
            fullness: 0,
            main: 0,
            max: 100,
        }
    ) {
        // console.log(PoolCapacity);
        const prcnt = (PoolCapacity.fullness / PoolCapacity.main) * 100 || 0;
        // const mainPrcnt =
        //     (100 * PoolCapacity.fullness) / PoolCapacity.main || 0;
        let overPrcnt =
            ((PoolCapacity.fullness - PoolCapacity.main) /
                (PoolCapacity.max - PoolCapacity.main)) *
            100;
        if (overPrcnt < 0) overPrcnt = 0;
        // console.log(prcnt, overPrcnt);
        return (
            <div className="capacity-container">
                <span className="min">
                    {prcnt > 100 ? PoolCapacity.main : 0}
                </span>

                <div
                    className="load"
                    style={{
                        width: `${prcnt}%`,
                    }}
                />
                <div
                    className="load another"
                    style={{
                        width: `${overPrcnt}%`,
                    }}
                />

                <span className="label">
                    Capacity: {PoolCapacity.fullness} ({prcnt.toFixed(2)}%)
                </span>
                <span className="max">
                    {prcnt > 100 ? PoolCapacity.max : PoolCapacity.main}
                </span>
            </div>
        );
    }
}

function MaxPoolCapacityBlock({ data, changePoolCapacity }) {
    const [rangeValue, setRangeValue] = useState(data.capConst2 * 100);
    const [capConst1, setCapConst1] = useState("");

    const [[lowerLimit, upperLimit], setLimits] = useState(() => {
        const limits = window.localStorage.getItem("manage_settings_limits");
        if (limits) return JSON.parse(limits);
        return [0, 300];
    });

    const range = useRef(null);
    const rangeHint = useRef(null);

    useEffect(() => {
        setRangeValue(data.capConst2 * 100);
    }, [data]);

    useDebounceEffect(
        () => {
            if (typeof lowerLimit === "number" && upperLimit) {
                if (lowerLimit < upperLimit) {
                    if (+rangeValue < +lowerLimit) setRangeValue(lowerLimit);
                    if (+rangeValue > +upperLimit) setRangeValue(upperLimit);
                    window.localStorage.setItem(
                        "manage_settings_limits",
                        JSON.stringify([lowerLimit, upperLimit])
                    );
                }
            }
        },
        900,
        [lowerLimit, upperLimit]
    );

    useEffect(() => {
        if (range.current) {
            const min = range.current.min;
            const max = range.current.max;
            const val = range.current.value;

            range.current.style.backgroundSize =
                ((val - min) * 100) / (max - min) + "% 100%";

            rangeHint.current.style.left =
                ((val - min) * 100) / (max - min) + "%";
        }
    }, [rangeValue, lowerLimit, upperLimit]);

    return (
        <>
            <div className="mini-block">
                <div className="inputs mini">
                    <label htmlFor="poolAmount">Amount </label>
                    <input
                        type="text"
                        id="poolAmount"
                        placeholder={data.capConst1}
                        value={capConst1}
                        onChange={({ target }) => {
                            const input = target.value.replace(/\D/g, "");
                            setCapConst1(input);
                        }}
                    />
                </div>
                <div className="inputs mini">
                    <label htmlFor="poolLowerLimit">Lower limit </label>
                    <input
                        type="text"
                        id="poolLowerLimit"
                        placeholder="0000"
                        value={lowerLimit}
                        onChange={({ target }) => {
                            const input = target.value.replace(/\D/g, "");
                            setLimits((prev) => [+input, prev[1]]);
                        }}
                    />
                </div>
                <div className="range-block">
                    <span>{lowerLimit}</span>
                    <div className="range-container">
                        {/*capConst2*/}
                        <input
                            type="range"
                            min={lowerLimit}
                            max={upperLimit}
                            value={rangeValue}
                            ref={range}
                            onChange={({ target }) => {
                                setRangeValue(target.value);
                            }}
                            className="range"
                        />

                        <div className="hint" ref={rangeHint}>
                            {(+rangeValue).toFixed(0)}
                        </div>
                    </div>
                    <span>{upperLimit}</span>
                </div>
                <div className="inputs mini">
                    <label htmlFor="poolUpperLimit">Upper limit </label>
                    <input
                        type="text"
                        id="poolUpperLimit"
                        placeholder="0000"
                        value={upperLimit}
                        onChange={({ target }) => {
                            const input = target.value.replace(/\D/g, ""); // Удаление всех символов, кроме цифр
                            setLimits((prev) => [prev[0], +input]);
                        }}
                    />
                </div>
            </div>
            <button
                style={{ marginTop: "10px" }}
                onClick={() => {
                    const sendData = {};
                    if (rangeValue !== data.capConst2 * 100) {
                        const toSave = (rangeValue / 100).toFixed(2);
                        sendData.capConst2 = toSave;
                    }
                    if (capConst1) sendData.capConst1 = capConst1;
                    handleSave(sendData);
                    setCapConst1("");
                }}
            >
                Save
            </button>
        </>
    );

    function handleSave(newData) {
        changePoolCapacity.mutate(
            {
                ...data,
                ...newData,
            },
            {
                onSuccess: (data) => {
                    console.log(data);
                    toast.success(
                        `Data in Max pool capacity changed to ${JSON.stringify(
                            newData
                        )}`
                    );
                },
                onError: (error) => {
                    console.error(error);
                    const msg = error.response.data;
                    toast.error(msg);
                },
            }
        );
    }
}
