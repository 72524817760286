import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import { ReactComponent as OptionsArrowSvg } from "./svg/OptionArrow.svg";
import { useState } from "react";

export default function LineGraph({ data }) {
    const [allDataType, setAllDataType] = useState({
        label: "Withdrawal",
        value: "withdrawal", //withdrawalOptionStatisticObject || incomeOptionStatisticObject
    });
    const dataTypeList = [
        {
            label: "Withdrawal",
            value: "withdrawal",
        },
        {
            label: "Income",
            value: "income",
        },
    ];
    const optionsList = data.options;

    const seriesData = getYaxis();
    const xAxisData = data[optionsList[0]].date;

    const options = {
        title: {
            show: false,
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        legend: {
            orient: "horizontal",
            top: "auto",
            left: "auto",
            right: "auto",
            // data: legendData,
            selectedMode: true,
            textStyle: {
                color: "#ffff",
            },
            show: true,
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            containLabel: true,
        },
        xAxis: [
            {
                type: "category",
                boundaryGap: false,
                // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                data: xAxisData,
            },
        ],
        yAxis: [
            {
                type: "value",
            },
        ],
        series: seriesData,
        // series: [
        //     {
        //         name: "Email",
        //         type: "line",
        //         stack: "Total",
        //         areaStyle: {},
        //         emphasis: {
        //             focus: "series",
        //         },
        //         data: [120, 132, 101, 134, 90, 230, 210],
        //     },
        //     {
        //         name: "Union Ads",
        //         type: "line",
        //         stack: "Total",
        //         areaStyle: {},
        //         emphasis: {
        //             focus: "series",
        //         },
        //         data: [220, 182, 191, 234, 290, 330, 310],
        //     },
        //     {
        //         name: "Video Ads",
        //         type: "line",
        //         stack: "Total",
        //         areaStyle: {},
        //         emphasis: {
        //             focus: "series",
        //         },
        //         data: [150, 232, 201, 154, 190, 330, 410],
        //     },
        //     {
        //         name: "Direct",
        //         type: "line",
        //         stack: "Total",
        //         areaStyle: {},
        //         emphasis: {
        //             focus: "series",
        //         },
        //         data: [320, 332, 301, 334, 390, 330, 320],
        //     },
        //     {
        //         name: "Search Engine",
        //         type: "line",
        //         stack: "Total",
        //         label: {
        //             show: true,
        //             position: "top",
        //         },
        //         areaStyle: {},
        //         emphasis: {
        //             focus: "series",
        //         },
        //         data: [820, 932, 901, 934, 1290, 1330, 1320],
        //     },
        // ],
    };

    return (
        <div>
            <div className="graph-settings">
                <h2>Options statistics by period</h2>
                <div className="select-name">
                    <div className="headlist">
                        {allDataType.label} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {dataTypeList.map((i, n) => (
                            <li
                                onClick={() => {
                                    setAllDataType(i);
                                }}
                                className={chekClassForList(
                                    i.label,
                                    allDataType
                                )}
                                key={n}
                            >
                                {i.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="chrt">
                <ReactEcharts
                    option={options}
                    echarts={echarts}
                    style={{ height: "100%", width: "100%" }}
                    // onEvents={{
                    //     click: onChartClick,
                    //     legendselectchanged: onChartLegendselectchanged,
                    // }}
                />
            </div>
        </div>
    );
    function getYaxis() {
        return optionsList
            .sort((a, b) => getOptionName(a, true) - getOptionName(b, true))
            .map((i) => {
                return {
                    name: i,
                    type: "line",
                    stack: "Total",
                    // label: {
                    //     show: true,
                    //     position: "top",
                    // },
                    areaStyle: {},
                    emphasis: {
                        focus: "series",
                    },
                    data: data[i][allDataType.value],
                };
            });
    }
    function chekClassForList(type, data) {
        if (type === data.label) return "active";

        return "";
    }

    function getOptionName(id, index = false) {
        const order = [
            "IRON",
            "ALUMINUM",
            "STEEL",
            "COPPER",
            "TITANIUM",
            "BRONZE",
            "SILVER",
            "GOLD",
            "PLATINUM",
            "PULSAR",
        ];
        // console.log(order.indexOf(id.toUpperCase()));
        return index ? order.indexOf(id.toUpperCase()) + 1 : order[id - 1];
    }
}
