import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useRequestsMethods from "../../../hook/useRequestsMethods";
import { useEffect, useState } from "react";
import { useDebounceEffect } from "../../../hook/useDebounceEffect";
import Head from "./Head";
import List from "./List";
import UserModal from "../../Users/UserModal";

export default function WithdrawList() {
    // list filters
    const defaulFilters = {
        size: 10,
        page: 0,
        status: "PENDING", //"PENDING" | "APPROVED" | "DECLINED"
    };

    const [sendFilterData, setSendFilterData] = useState(defaulFilters);
    const [selectedItems, setSelectedItems] = useState([]);

    const [showModal, setShowModal] = useState(false);

    // DO PAGONATION

    const { getWithdList } = useRequestsMethods();
    const {
        data = {
            content: [],
            empty: true,
            first: true,
            last: true,
            number: 0,
            numberOfElements: 0,
            pageable: {
                offset: 0,
                pageNumber: 0,
                pageSize: defaulFilters.size,
                paged: true,
                sort: { unsorted: true, sorted: false, empty: true },
                unpaged: false,
            },
            totalElements: 0,
        },
        isError: ErrorList,
        isLoading,
        isFetching,
        isPending,
    } = useQuery({
        queryKey: ["withdList", sendFilterData],
        queryFn: RequestList,
        placeholderData: keepPreviousData,

        retry: false,
    });

    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        setTotalPages(() => {
            return data.totalElements > 0
                ? Math.ceil(data.totalElements / sendFilterData.size)
                : 1;
        });
        // setSelectedItems([]);
    }, [data]);

    useEffect(() => {
        setSelectedItems([]);
    }, [sendFilterData]);

    // console.log(data);
    // console.log(isLoading, isFetching, isPending);

    return (
        <div className="witd-list">
            <Head
                changeFilter={changeFilter}
                defaultData={defaulFilters}
                realData={data}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                totalPages={totalPages}
            />
            <List
                data={data.content}
                selectFromList={selectFromList}
                selectedItems={selectedItems}
                isFetching={isFetching}
                openUserModal={setShowModal}
            />

            {showModal && (
                <UserModal
                    userTag={showModal}
                    close={() => {
                        setShowModal(false);
                    }}
                />
            )}
        </div>
    );

    function selectFromList(item, type = true, all = false) {
        setSelectedItems((prev) => {
            if (all) {
                return item;
            } else {
                const newData = [...prev.filter((i) => i.uuid !== item.uuid)];
                if (type) newData.push(item);
                return newData;
            }
        });
    }

    function changeFilter(data) {
        setSendFilterData(data);
    }

    async function RequestList() {
        const { data } = await getWithdList(sendFilterData);
        return data;
    }
}
