export default function ListItem({
    item,
    selectName = () => {},
    selectDate = () => {},
}) {
    // console.log(item)
    const [YYYY, M, D] = item?.timestamp || ["YYYY", "MM", "DD"];
    const date = `${D.toString().padStart(2, "0")}/${M.toString().padStart(
        2,
        "0"
    )}/${YYYY}`;
    return (
        <div className={`item`}>
            {/* <div className="img">
                {item.img && <img src={item.img} alt="a" />}
            </div> */}
            <div className="key">ID: {item?.personId}</div>
            <div
                className="key pointer"
                onClick={() => {
                    selectName(item?.personName || false);
                }}
            >
                {item?.personName}
            </div>
            <div className="key">{item?.personRole}</div>
            <div className="key">
                <div className="text">{item?.message}</div>
            </div>
            <div
                className="key pointer"
                onClick={() => {
                    selectDate(date.replace("/", "-") || false);
                }}
            >
                {date}
            </div>
        </div>
    );
}
