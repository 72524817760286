import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useRequestsMethods from "../../../hook/useRequestsMethods";
import { ReactComponent as CapacityPoolSvg } from "../svg/capacityOutput.svg";
import { ReactComponent as OptionsArrowSvg } from "../svg/OptionArrow.svg";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDebounceEffect } from "../../../hook/useDebounceEffect";
import OptionController from "./OptionController";
import { PoolController } from "../right";

export default function Bottom() {
    const { get_the_filling_ratio, postSave_the_filling_ratio } =
        useRequestsMethods();

    const queryClient = useQueryClient();
    const {
        data: FillingRatio = null, //Changing the filling ratio
        isError: ErrorFillingRatio,
        isLoading: LoadFillingRatio,
    } = useQuery({
        queryKey: ["FillingRatio"],
        queryFn: getFillRatio,
    });

    const changeFillingRatio = useMutation({
        mutationFn: postSave_the_filling_ratio,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["FillingRatio"] });
        },
    });

    if (ErrorFillingRatio) return "Error";
    return (
        <div className="bottom">
            <PoolController />
            {/* <div className="big-block bg-block">
                <h2>
                    <CapacityPoolSvg /> Changing the reinvest multiplier
                </h2>

                <div className="row">
                    <div className="select-name">
                        <div className="headlist">
                            Andromeda <OptionsArrowSvg />
                        </div>
                        <ul className="list">
                            <li className={chekClassForList("all")}>
                                Andromeda
                            </li>
                            <li className={chekClassForList("year")}>Year</li>
                            <li className={chekClassForList("quarter")}>
                                Quarter
                            </li>
                        </ul>
                    </div>

                    <div className="select-name">
                        <div className="headlist">
                            --- <OptionsArrowSvg />
                        </div>
                        <ul className="list">
                            <li className={chekClassForList("all")}>---</li>
                            <li className={chekClassForList("year")}> ---</li>
                            <li className={chekClassForList("quarter")}>---</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="inputs">
                        <label htmlFor="multiplier">Enter multiplier</label>
                        <input type="text" placeholder="000%" />
                    </div>
                    <div className="timer">300%</div>
                </div>
            </div> */}
            <OptionController />
            {LoadFillingRatio && !FillingRatio ? (
                "Loading"
            ) : (
                <ChangingFillingRatio
                    data={FillingRatio}
                    chekClassForList={chekClassForList}
                    changeFillingRatio={changeFillingRatio}
                />
            )}
        </div>
    );

    function chekClassForList(type, check = "") {
        if (type === check) return "active";
        return "";
    }

    async function getFillRatio() {
        // try {
        const { data } = await get_the_filling_ratio();
        let tempSet = new Set();
        const newData = data.reduce(
            (acc, curr) => {
                tempSet.add(curr.optionName);
                acc.options = [...tempSet];
                if (!acc.stages[curr.optionName])
                    acc.stages[curr.optionName] = [];
                // acc.stages[curr.optionName].push(curr.fillingRate);
                acc.stages[curr.optionName][curr.stage] = curr.fillingRate;
                acc.number[curr.optionName] = getNumber(curr.optionName);
                return acc;
            },
            { options: [], stages: {}, number: {} }
        );
        // console.log(data);
        return newData;
        // } catch (error) {
        //     console.log("error:");
        //     console.log(error);
        //     throw new Error(error);
        // }

        function getNumber(option) {
            const order = [
                "IRON",
                "ALUMINUM",
                "STEEL",
                "COPPER",
                "TITANIUM",
                "BRONZE",
                "SILVER",
                "GOLD",
                "PLATINUM",
                "PULSAR",
            ];

            const index = order.indexOf(option);
            return index >= 0 ? index + 1 : 0;
        }
    }
}

function ChangingFillingRatio({ chekClassForList, data, changeFillingRatio }) {
    const [selectedOption, setSelectedOption] = useState(
        data.options[0] ? data.options[0] : "none"
    );
    const [selectedStage, setSelectedStage] = useState(false);
    const [selectedStageIndex, setSelectedStageIndex] = useState(false);
    const [newRatio, setNewRatio] = useState("");

    useEffect(() => {
        setSelectedStageIndex(false);
        setSelectedStage(false);
    }, [selectedOption]);
    // useDebounceEffect(
    //     () => {
    //         if (newRatio) {
    //             handleChangeRatio();
    //             setNewRatio("");
    //         }
    //     },
    //     1600,
    //     [newRatio]
    // );
    return (
        <div className="big-block bg-block">
            <h2>
                <CapacityPoolSvg /> Changing the filling ratio
            </h2>

            <div className="row">
                <div className="select-name">
                    <div className="headlist">
                        {selectedOption} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {renderList(
                            data.options,
                            setSelectedOption,
                            selectedOption
                        )}
                    </ul>
                </div>

                <div className="select-name">
                    <div className="headlist">
                        {selectedStageIndex
                            ? `Stage ${selectedStageIndex + 1}`
                            : `---`}{" "}
                        <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {renderList(
                            data.stages[selectedOption],
                            setSelectedStage,
                            selectedStage,
                            "Stage",
                            setSelectedStageIndex
                        )}
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="inputs">
                    <label htmlFor="multiplier">Enter multiplier</label>
                    <input
                        type="text"
                        placeholder="000%"
                        value={newRatio}
                        disabled={!selectedStage}
                        onChange={({ target }) => {
                            const input = target.value.replace(/[^0-9.]/g, "");
                            setNewRatio(input);
                        }}
                    />
                </div>
                <div className="timer">
                    {selectedStage
                        ? (
                              data.stages[selectedOption][selectedStageIndex] *
                              100
                          ).toFixed(2) + "%"
                        : "---"}
                </div>
            </div>
            <button
                onClick={() => {
                    if (!newRatio) return;
                    handleChangeRatio();
                    setNewRatio("");
                }}
            >
                <span>Save</span>
            </button>
        </div>
    );

    function renderList(
        list,
        setFunction,
        selectedItem,
        title = false,
        addFunc = false
    ) {
        return list.map((i, n) => {
            return (
                <li
                    className={chekClassForList(selectedItem, i)}
                    key={`head_list_${n}`}
                    onClick={() => {
                        setFunction(i);
                        if (addFunc) addFunc(n);
                    }}
                >
                    {title ? `${title} ${n + 1}` : i}
                </li>
            );
        });
    }

    function handleChangeRatio() {
        const newData = {
            optionNumber: data.number[selectedOption],
            optionStage: selectedStageIndex,
            fillingRatio: newRatio / 100,
        };
        console.log(newData);
        changeFillingRatio.mutate(newData, {
            onSuccess: (data) => {
                console.log(data);
                toast.success(
                    `For option ${selectedOption} (${
                        newData.optionNumber
                    }) / stage: ${
                        newData.optionStage + 1
                    } seted filling Ratio: ${newData.fillingRatio}`
                );
            },
            onError: (error) => {
                console.error(error);
                const msg = error.response.data;
                toast.error(msg);
            },
        });
    }
}
