import { useLocation, useNavigate } from "react-router-dom";

export default function MiniHead() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    return (
        <div className="page-mini-header">
            <div className="width-content">
                <ul>
                    <li
                        className={`${pathname === "/stata" ? "active" : ""}`}
                        onClick={() => {
                            navigate("/stata");
                        }}
                    >
                        Dashboard
                    </li>
                    <li
                        className={`${
                            pathname === "/stata/investments" ? "active" : ""
                        }`}
                        onClick={() => {
                            navigate("/stata/investments");
                        }}
                    >
                        Users / Investments
                    </li>
                    <li
                        className={`${
                            pathname === "/stata/options" ? "active" : ""
                        }`}
                        onClick={() => {
                            navigate("/stata/options");
                        }}
                    >
                        Options
                    </li>
                    <li
                        className={`${
                            pathname === "/stata/pool" ? "active" : ""
                        }`}
                        onClick={() => {
                            navigate("/stata/pool");
                        }}
                    >
                        Pool
                    </li>
                </ul>
            </div>
        </div>
    );
}
