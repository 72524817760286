import { useEffect, useState } from "react";
import { useDebounceEffect } from "../../../hook/useDebounceEffect";
import usePopUp from "../../../hook/usePopUp";
import useRequestsMethods from "../../../hook/useRequestsMethods";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

export default function Head({
    changeFilter,
    defaultData,
    realData,
    selectedItems,
    setSelectedItems,
    totalPages,
}) {
    const queryClient = useQueryClient();

    const [itemPerPage, setItemPerPage] = useState(defaultData.size);
    const [page, setPage] = useState(defaultData.page);
    const [status, setStatus] = useState(defaultData.status); //"PENDING" | "APPROVED" | "DECLINED"
    const statusList = ["PENDING", "APPROVED", "DECLINED"];
    const popOp = usePopUp();
    const { changeWithdStatus } = useRequestsMethods();

    useDebounceEffect(
        () => {
            changeFilter({
                size: itemPerPage,
                page: page,
                status: status,
            });
        },
        600,
        [itemPerPage, status]
    );
    useEffect(() => {
        changeFilter({
            size: itemPerPage,
            page: page,
            status: status,
        });
    }, [page]);

    return (
        <div className="filters">
            <div className="page-control">
                <button onClick={prevPage}>{`<-`}</button>
                <button onClick={nextPage}>{`->`}</button>

                <div className="text">
                    Page {page + 1} of {totalPages}
                </div>
                <div className="text">
                    Total items: {realData.totalElements}
                </div>
            </div>
            <div className="page-filters">
                <div className="inputs">
                    <label className="label" htmlFor="itemperpage">
                        Item per page ({itemPerPage})
                    </label>
                    <input
                        id="itemperpage"
                        type="text"
                        placeholder={`Item per page (${itemPerPage})`}
                        onChange={({ target }) => {
                            //ONLY NUMBERS
                            let newVal =
                                +target.value !== 0 ? +target.value : 10;
                            if (newVal > 20) newVal = 20;
                            setItemPerPage(newVal);
                            setPage(0);
                        }}
                    />
                </div>

                <div className="inputs">
                    <div className="label">Status</div>

                    <div className="own-pick">
                        <div className="own-head">{status}</div>
                        <div className="own-list">
                            {renderListForOwnPick(statusList, setStatus)}
                        </div>
                    </div>
                </div>

                <div
                    className={`inputs choose ${
                        selectedItems.length > 0 ? "show" : ""
                    }`}
                >
                    <div className="label">
                        Выбрано <span>{selectedItems.length}</span>
                    </div>
                    <button onClick={choseWhatToDo}>
                        <span>Choose</span>
                    </button>
                </div>
            </div>
        </div>
    );

    async function choseWhatToDo() {
        const answ = await popOp({
            title: "Aproove or Decline ?",
            text: (
                <>
                    <p>
                        You pick <b>{selectedItems.length}</b> requests. <br />{" "}
                        What we do?
                    </p>
                </>
            ),
            btn_text: "Aproove",
            cancel_btn_text: "Decline",

            apr_func: () => {
                return "APPROVED";
            },
            dec_func: () => {
                return "DECLINED";
            },
        });

        if (!answ) return;
        changeStatus(selectedItems, answ);
        // console.log(answ);
    }

    async function changeStatus(list, status) {
        try {
            const res = await Promise.all(
                list.map(async (i) => {
                    try {
                        const { data } = await changeWithdStatus({
                            ...i,
                            status,
                        });
                        return data;
                        // answers.push(result);
                    } catch (err) {
                        console.error(err);
                        return false;
                        // toast.error("Err");
                    }
                })
            );
            // console.log(res);
            // map res and check for  errors
            //toast res
            let errors = [];
            let sucs = [];
            res.map((item) => {
                if (item.status === "SUCCESS") {
                    sucs.push(item);
                } else {
                    errors.push(item);
                }
            });

            if (sucs.length === selectedItems.length) {
                toast.success(`Requests was successfully '${status}'`);
            } else {
                toast.warning(
                    `Successfully ${sucs.length} of ${selectedItems.length} and Error ${errors.length} of ${selectedItems.length}`,
                    {
                        autoClose: false,
                    }
                );
            }

            setSelectedItems([]);
            queryClient.invalidateQueries({ queryKey: ["withdList"] });
            // console.log(answers);
        } catch (err) {
            console.log(err);
            toast.error("Err");
        }
    }
    function renderListForOwnPick(list, func) {
        return list.map((i, k) => {
            return (
                <div
                    className="itm"
                    key={k}
                    onClick={() => {
                        func(i);
                        setPage(0);
                    }}
                >
                    {i}
                </div>
            );
        });
    }
    function nextPage() {
        // NAdo znat` skoko vsego stranic
        let all = totalPages - 1;

        setPage((prev) => {
            const newPage = prev + 1;
            return newPage > all ? all : newPage;
        });
    }
    function prevPage() {
        // NAdo znat` skoko vsego stranic
        setPage((prev) => {
            let back = --prev;
            return back < 0 ? 0 : back;
        });
    }
}
