import Left from "./left";
import Right from "./right";

export default function ManageContainer() {
    return (
        <div className="flex-split">
            <Left />
            {/* <Right /> */}
        </div>
    );
}
