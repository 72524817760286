import { useQuery } from "@tanstack/react-query";
import useRequestsMethods from "../hook/useRequestsMethods";
import { useState } from "react";
import UserModal from "../Components/Users/UserModal";
import { useDebounceEffect } from "../hook/useDebounceEffect";
import { toast } from "react-toastify";

export default function UsersPage() {
    const [date, setDate] = useState(false);
    const [mail, setMail] = useState("");
    const { getUsersList } = useRequestsMethods();
    //controlfruit887@gmail.com

    const [showModal, setShowModal] = useState(false);

    // useDebounceEffect(()=>{},600)

    // const {
    //     data = [],
    //     isError,
    //     isLoading,
    //     isFetching,
    //     isPending,
    // } = useQuery({
    //     queryKey: ["usersList", date, mail],
    //     queryFn: () => fetchUsersList(date, mail),
    //     // placeholderData: keepPreviousData,

    //     retry: false,
    // });

    // console.log(data);
    return (
        <div className="page-block users">
            <div className="width-content">
                <h1>Users</h1>
                <div
                    className="inputs"
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                    }}
                >
                    <h3
                        style={{
                            width: "100%",
                        }}
                    >
                        Type E-Mail
                    </h3>
                    <input
                        type="text"
                        value={mail}
                        onChange={({ target }) => {
                            setMail(target.value);
                        }}
                    />
                    <button onClick={openModal}>
                        <span>Check</span>
                    </button>
                </div>

                {showModal && (
                    <UserModal
                        userTag={showModal}
                        close={() => {
                            setShowModal(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
    function openModal() {
        if (!mail) return toast.warning("Type mail first");
        setShowModal(mail);
    }
    async function fetchUsersList(date = "", mail = "") {
        try {
            const { data } = await getUsersList();
            return data;
        } catch (err) {
            return err;
        }
    }
}
