import { useEffect, useState } from "react";
import { ReactComponent as ExitSvg } from "./svg/exit.svg";
import { logoutUser } from "../../Redux/userApi";
import { useDispatch, useSelector } from "react-redux";
import useRequestsMethods from "../../hook/useRequestsMethods";

export default function Header() {
    const [isMobile, setIsMobile] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);

    const user = useSelector((state) => state.persistedReducer.user);

    const { logout } = useRequestsMethods();

    const dispatch = useDispatch();

    useEffect(() => {
        function handleScroll() {
            console.log(window.pageYOffset);
            if (window.pageYOffset <= 0) {
                setIsScrolling(false);
            } else {
                setIsScrolling(true);
            }
        }
        if (isMobile) {
            window.addEventListener("scroll", handleScroll);
        }
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isMobile]);

    return (
        <header className={`${isScrolling ? "hide-heder-mobile" : ""}`}>
            <div className="width-content">
                <div className="head">
                    <div className="title">
                        {/* Balance: <span className="accent">$10 000</span> */}
                        Admin
                    </div>
                </div>

                <div className="end">
                    <div className="user">
                        <div className="name">{user.email}</div>
                        <div className="avatar">
                            <img src={"/src/avatar.svg"} alt="1" />
                        </div>
                        {/* <ArrowDownSvg /> */}
                        <span
                            onClick={() => {
                                logoutUser(dispatch, logout);
                            }}
                        >
                            <ExitSvg />
                        </span>
                    </div>
                </div>
            </div>
        </header>
    );
}
