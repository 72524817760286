import { useEffect, useState } from "react";
import UserList from "./UserList";

export default function BigBlock({
    usersData = [],
    info,
    changeRole,
    deleteUser,
}) {
    // const [users, setUsers] = useState(usersData);

    // useEffect(() => {
    //     getData(setUsers);
    // }, []);
    if (!usersData) return <div className="big-block"> Loading </div>;
    return (
        <div className="big-block">
            <div className="left">
                <div className="title">{info.title}</div>
                <div className="desc">{info.description}</div>
            </div>
            <div className="right">
                <UserList
                    users={usersData}
                    changeRole={changeRole}
                    deleteUser={deleteUser}
                />
            </div>
        </div>
    );
}
