import { ReactComponent as UsersSvg } from "../left/svg/users.svg";
import { ReactComponent as OptionsArrowSvg } from "../left/svg/OptionArrow.svg";
import { useEffect, useState } from "react";
import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";

export default function Graph({ chartData }) {
    const [chartType, setChartType] = useState("users");
    const [legendData, setLegendData] = useState([]);

    const { data: fetchedData, pending, error, errors } = chartData;
    // console.log(fetchedData);
    const [userViewList] = useState([
        {
            label: "Referrals / not referrals",
            value: ["referral", "notReferral"],
        },
        {
            label: "Active do / active sleep",
            value: ["active", "sleep"],
        },
        {
            label: "Online / offline",
            value: ["on", "off"],
        },
    ]);
    const [investViewList] = useState([
        {
            label: "Invest / Income / Withdraw",
            value: "InvestIncomeWithdraw",
        },
        {
            label: "Invest / Reinvest",
            value: "InvestReinvest",
        },
    ]);
    const [userViewActive, setUserViewActive] = useState(userViewList[0]);
    const [optionsActive, setOptionsActive] = useState(false);
    const [optionsList, setOptionsList] = useState([]);

    const useList = {
        users: userViewList,
        invests: investViewList,
    };

    const [seriesData, setSeriesData] = useState([]);
    // useEffect(() => {
    //     // console.log(optionsList);
    //     if (chartType === "invests") {
    //     }
    // }, [chartType]);
    useEffect(() => {
        if (error) {
            setSeriesData([]);
        } else {
            if (!pending) {
                setSeriesData(genChartData(chartType));
            }
        }
    }, [pending, fetchedData, chartType, error, userViewActive, optionsActive]);
    useEffect(() => {
        if (chartType === "users") {
            setUserViewActive(userViewList[0]);
        } else {
            getListOfOptions();
            setUserViewActive(investViewList[0]);
        }
    }, [chartType]);
    const options = {
        title: {
            // text:
            //     typePoint === "users" ? "Refferals Users" : "Refferals Rewards",
            // x: "center",
            // textStyle: {
            //     color: "#f5f5f5",
            // },
            show: false,
        },
        tooltip: {
            trigger: "item",
            // formatter: "{b} : {c} ({d}%)",
            formatter(param) {
                // correct the percentage
                // console.log(param.name);
                return param.name + " (" + param.percent + "%)";
            },
        },
        legend: {
            orient: "verical",
            top: "50%",
            left: "75%",
            data: legendData,
            selectedMode: true,
            textStyle: {
                color: "#ffff",
            },
            show: true,
        },
        series: [
            {
                name:
                    chartType === "users"
                        ? "Refferals Users"
                        : "Refferals Rewards",
                type: "pie",
                radius: ["40%", "75%"],
                center: ["50%", "50%"],

                // adjust the start angle
                // startAngle: 180,
                label: {
                    show: false,
                    position: "center",
                    formatter(param) {
                        // correct the percentage
                        // console.log(param);
                        // return param.percent * 2 + "%";
                        return param.value;
                    },
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: "18",
                        fontWeight: "bold",
                        color: "#f5f5f5",
                    },
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                },
                selectedMode: "single", // Разрешить выбор нескольких элементов

                // maintainAspectRatio: false, //отключаем пропорциональность размера
                // responsive: true,
                // grid: {
                //     width: "140%", //задаем ширину графика
                //     height: "40%", //задаем высоту графика
                // },
                data: seriesData,
            },
        ],
    };
    return (
        <div className="big-block bg-block info-block">
            <div className="title">
                <div className="title">
                    <div className="text">
                        <UsersSvg /> Viewing by{" "}
                        {chartType === "users" ? "users" : "investments"}
                    </div>
                </div>
            </div>
            <div className="chart-options">
                <div className="select-name">
                    <div className="headlist">
                        {/* Invest / Income / Withdraw <OptionsArrowSvg /> */}
                        {userViewActive.label} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {useList[chartType].map((i, n) => (
                            <li
                                onClick={() => {
                                    setUserViewActive(i);
                                }}
                                className={chekClassForList(i.label, true)}
                                key={n}
                            >
                                {i.label}
                            </li>
                        ))}
                    </ul>
                </div>
                {chartType === "invests" && fetchedData[0] && optionsActive && (
                    <div className="select-name">
                        <div className="headlist">
                            {optionsActive.label} <OptionsArrowSvg />
                        </div>
                        <ul className="list">
                            {optionsList.map((i, n) => (
                                <li
                                    onClick={() => {
                                        setOptionsActive(i);
                                    }}
                                    className={chekClassForList(i.label, true)}
                                    key={n}
                                >
                                    {i.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className="chart" style={{ height: "370px" }}>
                <div className="switch-charts">
                    <div
                        className="sw-btn"
                        onClick={() => {
                            setChartType("users");
                        }}
                    >
                        users
                    </div>
                    <div
                        className="sw-btn"
                        onClick={() => {
                            setChartType("invests");
                        }}
                    >
                        invests
                    </div>
                </div>
                <ReactEcharts
                    option={options}
                    echarts={echarts}
                    style={{ height: "100%", width: "100%" }}
                    onEvents={
                        {
                            // click: handleClick,
                        }
                    }
                />
            </div>
        </div>
    );

    function getListOfOptions() {
        const optionsSet = new Set();
        fetchedData[0].map((i) => optionsSet.add(i.optionNumber));
        const list = [...optionsSet]
            .sort((a, b) => a - b)
            .map((i) => ({
                value: i,
                label: getOptyionName(i),
            }));
        // console.log(optionsSet);
        // console.log(list);
        setOptionsList([
            {
                value: "all",
                label: "All",
            },
            ...list,
        ]);
        setOptionsActive({
            value: "all",
            label: "All",
        });
        // setOptionsActive(list[0]);
    }
    function genChartData(type) {
        const dataToMap =
            type === "users"
                ? getChartForUser(fetchedData[1])
                : getChartForInvests(fetchedData[0]);
        // console.log(dataToMap);
        // const dataForChart = ;
        // console.log(dataForChart);
        return dataToMap;
    }

    function getChartForInvests(data) {
        const keyForChart = userViewActive.value;
        const dataForView =
            keyForChart === "InvestIncomeWithdraw"
                ? dataInvIncWitd()
                : dataInvReinv();
        if (!dataForView) return [];
        const keysForChart = Object.keys(dataForView);
        // console.log(keyForChart);
        // console.log(dataForView);
        // return [];
        const color = ["#3A73FF", "#3A44FF", "#01cbeb", "red"];
        const newLegend = [];
        const rd = keysForChart.map((item, key) => {
            newLegend.push(`${item}`);
            return {
                name: item,
                value: dataForView[item],
                itemStyle: {
                    color: color[key],
                    borderRadius: 4,
                    borderColor: "#010d1a",
                    borderWidth: 2,
                },
            };
        });
        // // options.legend.data = newLegend;
        setLegendData(newLegend);
        return rd;

        function dataInvReinv() {
            return data
                .filter((i) =>
                    optionsActive.value === "all"
                        ? i
                        : i.optionNumber === optionsActive.value
                )
                .reduce(
                    (acc, curr) => {
                        return {
                            ...acc,
                            [curr.investType]: acc[curr.investType] + curr.sum,
                        };
                    },
                    {
                        REINVEST: 0,
                        INVEST: 0,
                        // [optionsActive.label]: optionsActive.value,
                    }
                );
        }
        function dataInvIncWitd() {
            return data
                .filter((i) =>
                    optionsActive.value === "all"
                        ? i
                        : i.optionNumber === optionsActive.value
                )
                .reduce(
                    (acc, curr) => {
                        return {
                            ...acc,
                            invest: acc.invest + curr.sum,
                            income: acc.income + curr.fullness,
                            withdraw: acc.withdraw + curr.balance,
                        };
                    },
                    {
                        invest: 0,
                        income: 0,
                        withdraw: 0,
                        // [optionsActive.label]: optionsActive.value,
                    }
                );
        }
    }

    function getChartForUser(data) {
        const keysForChart = userViewActive.value;
        const color = ["#3A73FF", "#3A44FF", "#01cbeb"];
        const newLegend = [];

        if (typeof keysForChart !== "object") return [];
        const rd = keysForChart
            // .sort((a, b) => a.referralLevel - b.referralLevel)
            .map((item, key) => {
                // if (key > 1) return "";
                // console.log(color[key]);
                // newLegend.push(`${item}: ${data[item]}`);
                newLegend.push(`${item}`);
                return {
                    name: item,
                    value: data[item],
                    itemStyle: {
                        color: color[key],
                        borderRadius: 4,
                        borderColor: "#010d1a",
                        borderWidth: 2,
                    },
                };
            });
        // options.legend.data = newLegend;
        setLegendData(newLegend);
        return rd;
    }

    function getOptyionName(id) {
        const order = [
            "IRON",
            "ALUMINUM",
            "STEEL",
            "COPPER",
            "TITANIUM",
            "BRONZE",
            "SILVER",
            "GOLD",
            "PLATINUM",
            "PULSAR",
        ];
        return order[id - 1];
    }

    function chekClassForList(type, users = true) {
        if (users) {
            if (type === userViewActive.label) return "active";
        }
        return "";
    }
}
