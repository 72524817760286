import { useQuery } from "@tanstack/react-query";
import ManageContainer from "../Components/Manage";
import useRequestsMethods from "../hook/useRequestsMethods";

export default function Manage() {
    return (
        <div className="page-block manage-page">
            <div className="width-content">
                <ManageContainer />
            </div>
        </div>
    );
}
