import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import { ReactComponent as OptionsArrowSvg } from "./svg/OptionArrow.svg";
import { useEffect, useState } from "react";

export default function Capacity({ data, getOptionName }) {
    const [activeOption, setActiveOption] = useState(false);
    const [optionList, setOptionList] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [dataType, setDataType] = useState({
        value: "incomeStatisticObject", // withdrawalStatisticObject
        label: "Income", // withdrawalStatisticObject
    });

    // console.log(data);
    useEffect(() => {
        setActiveOption(false);
        setOptionList(false);
        if (data) {
            getOptionsList();
        }
    }, [data, dataType]);
    useEffect(() => {
        if (activeOption) {
            getChartData();
        }
    }, [activeOption, dataType]);

    const options = {
        title: {
            show: false,
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                snap: true,
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            show: false,
        },
        xAxis: {
            type: "category",
            data: ["0", "10", "20", "30", "40", "50", "60", "70", "80", "90"],
        },
        yAxis: {
            type: "value",
            // axisPointer: { show: true },
        },
        series: [
            {
                // data: [120, 200, 150, 80, 70, 110, 130],
                data: chartData.y,
                type: "bar",
                showBackground: true,
                backgroundStyle: {
                    color: "rgba(180, 180, 180, 0.2)",
                },
            },
        ],
    };
    return (
        <div>
            <div className="graph-settings">
                <h2>Capacity</h2>

                <div className="select-name">
                    <div className="headlist">
                        {dataType?.label ?? "---"} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        <li
                            onClick={() => {
                                setDataType({
                                    value: "incomeStatisticObject", //
                                    label: "Income",
                                });
                            }}
                            className={chekClassForList(
                                "incomeStatisticObject",
                                dataType
                            )}
                        >
                            Income
                        </li>
                        <li
                            onClick={() => {
                                setDataType({
                                    value: "withdrawalStatisticObject",
                                    label: "Withdrawal",
                                });
                            }}
                            className={chekClassForList(
                                "withdrawalStatisticObject",
                                dataType
                            )}
                        >
                            Withdrawal
                        </li>
                    </ul>
                </div>
                <div className="select-name">
                    <div className="headlist">
                        {activeOption?.label ?? "---"} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {optionList.map((i, n) => (
                            <li
                                onClick={() => {
                                    setActiveOption(i);
                                }}
                                className={chekClassForList(
                                    i.label,
                                    activeOption
                                )}
                                key={n}
                            >
                                {i.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="chrt">
                <ReactEcharts
                    option={options}
                    echarts={echarts}
                    style={{ height: "100%", width: "100%" }}
                />
            </div>
        </div>
    );
    function getChartData() {
        // console.log(data[activeOption.value])
        if (data[dataType.value]) {
            if (data[dataType.value][activeOption.value]) {
                setChartData(data[dataType.value][activeOption.value]);
            }
        }
    }
    function chekClassForList(type, checkBy = true) {
        if (type === checkBy.label) return "active";

        return "";
    }

    function getOptionsList() {
        const optionsKeys = Object.keys(data[dataType.value] ?? []);
        const list = optionsKeys.map((i) => ({
            label: getOptionName(i),
            value: i,
        }));
        setActiveOption(list[0]);
        setOptionList(list);
    }
}
