import { ReactComponent as BalanceSvg } from "./svg/balance.svg";
import { ReactComponent as OptionsArrowSvg } from "./svg/OptionArrow.svg";

import { ReactComponent as StatArrowSvg } from "../svg/statArrow.svg";

import { ReactComponent as WithdrawSvg } from "./svg/balance/withdraw.svg";
import { ReactComponent as IncomeSvg } from "./svg/balance/income.svg";
import { ReactComponent as CommisionsSvg } from "./svg/balance/commisions.svg";
import { ReactComponent as UserSvg } from "./svg/balance/user.svg";

import { useState } from "react";
import useRequestsMethods from "../../../../hook/useRequestsMethods";
import { useQuery } from "@tanstack/react-query";

export default function Balance({ balanceData, checkOnLoad }) {
    const [datePoint, setDatePoint] = useState("kkk");
    const {
        getDashboardRefBal,
        getDashboardRefFundBal,
        getDashboardInvestFundBal,
    } = useRequestsMethods();

    const {
        data: { data: { balance: Referral_balance } } = {
            data: { balance: 0 },
        },
        isError,
        isLoading,
    } = useQuery({
        queryKey: ["DashboardRefBal"],
        queryFn: getDashboardRefBal,

        retry: false,
        // refetchOnWindowFocus: false,
    });
    const {
        data: { data: { balance: Referral_fund_balance } } = {
            data: { balance: 0 },
        },
        isError: ErrorReferral_fund_balance,
        isLoading: LoadingReferral_fund_balance,
    } = useQuery({
        queryKey: ["DashboardRefFundBal"],
        queryFn: getDashboardRefFundBal,

        retry: false,
        // refetchOnWindowFocus: false,
    });
    const {
        data: { data: { balance: Invest_fund_balance } } = {
            data: { balance: 0 },
        },
        isError: ErrorInvest_fund_balance,
        isLoading: LoadingInvest_fund_balance,
    } = useQuery({
        queryKey: ["DashboardInvestFundBal"],
        queryFn: getDashboardInvestFundBal,

        retry: false,
        // refetchOnWindowFocus: false,
    });
    const { data, error, pending, errors } = balanceData;

    // console.log(Invest_fund_balance);

    const [balanceWithdraw, balanceIncome, balanceBalance, balanceCommission] =
        data;

    if (error) {
        console.log(errors);
        return "Error";
    }
    return (
        <div className="info-block">
            <div className="title">
                <div className="text">
                    <BalanceSvg /> Balance
                </div>
                <div className="select-name">
                    <div className="headlist">
                        Week <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        <li className={chekClassForList("all")}>All time</li>
                        <li className={chekClassForList("year")}>Year</li>
                        <li className={chekClassForList("quarter")}>Quarter</li>
                        <li className={chekClassForList("month")}>Month</li>
                        <li className={chekClassForList("week")}>Week</li>
                    </ul>
                </div>
            </div>

            <div className="line-list">
                <div className="item">
                    <div className="key">
                        <UserSvg /> User balance
                    </div>
                    <div className="key">${checkOnLoad(balanceBalance)}</div>
                    <div className="key">
                        <div className="status status-plus">
                            <StatArrowSvg /> +7 %
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="key">
                        <IncomeSvg /> Income balance
                    </div>
                    <div className="key">${checkOnLoad(balanceIncome)}</div>
                    <div className="key">
                        <div className="status status-minus">
                            <StatArrowSvg /> -7 %
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="key">
                        <WithdrawSvg /> Withdraw Balance
                    </div>
                    <div className="key">${checkOnLoad(balanceWithdraw)}</div>
                    <div className="key">
                        <div className="status status-plus">
                            <StatArrowSvg /> +7 %
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="key">
                        <CommisionsSvg /> Commisions Balance
                    </div>
                    <div className="key">${checkOnLoad(balanceCommission)}</div>
                    <div className="key">
                        <div className="status status-minus">
                            <StatArrowSvg /> +7 %
                        </div>
                    </div>
                </div>

                <div className="item">
                    <div className="key">
                        <BalanceSvg /> Referral balance
                    </div>
                    <div className="key">${checkOnLoad(Referral_balance)}</div>
                    <div className="key">
                        <div className="status status-minus">
                            <StatArrowSvg /> +7 %
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="key">
                        <BalanceSvg /> Referral fund balance
                    </div>
                    <div className="key">
                        ${checkOnLoad(Referral_fund_balance)}
                    </div>
                    <div className="key">
                        <div className="status status-minus">
                            <StatArrowSvg /> +7 %
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="key">
                        <BalanceSvg /> Invest fund balance
                    </div>
                    <div className="key">
                        ${checkOnLoad(Invest_fund_balance)}
                    </div>
                    <div className="key">
                        <div className="status status-minus">
                            <StatArrowSvg /> +7 %
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function chekClassForList(type) {
        if (type === datePoint) return "active";
        return "";
    }
}
