import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import { ReactComponent as OptionsArrowSvg } from "./svg/OptionArrow.svg";
// import { useEffect, useState } from "react";

export default function UsersCount({
    data,
    dateOptions,
    userDataDate,
    changeDate,
}) {
    const options = {
        title: {
            show: false,
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross",
                label: {
                    backgroundColor: "#6a7985",
                },
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
            },
        },
        grid: {
            show: false,
        },
        xAxis: {
            name: "Date",
            nameLocation: "center",
            nameGap: 30,
            type: "category",
            boundaryGap: false,
            // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            data: data.x,
        },
        yAxis: {
            name: "Value",
            nameLocation: "center",
            nameGap: 30,
            type: "value",
        },
        series: [
            {
                // data: [820, 300, 400, 932, 901, 934, 1290, 1330, 1320],
                data: data.y,
                type: "line",
                smooth: true,
                areaStyle: {},
            },
        ],
    };

    return (
        <div className="axis-chart users-count">
            <div className="graph-settings">
                <h2>Users</h2>
                <div className="select-name">
                    <div className="headlist">
                        {userDataDate.label} <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        {dateOptions.map((i, n) => (
                            <li
                                onClick={() => {
                                    changeDate(i);
                                }}
                                className={chekClassForList(i.label, true)}
                                key={n}
                            >
                                {i.label}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="chrt">
                <ReactEcharts
                    option={options}
                    echarts={echarts}
                    style={{ height: "100%", width: "100%" }}
                    // onEvents={+
                    //     {
                    //         // click: handleClick,
                    //     }
                    // }
                />
            </div>
        </div>
    );

    function chekClassForList(type, users = true) {
        if (type === userDataDate.label) return "active";

        return "";
    }
}
