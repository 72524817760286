import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useRequestsMethods from "../../../hook/useRequestsMethods";
import UsersCount from "./UsersCount";
import {
    getAllTime,
    getMonth,
    getQuarter,
    getWeek,
    getYear,
} from "../../../utils/GetDate";
import { useState } from "react";
import Deposits from "./Deposits";
import Capacity from "./Capacity";

export default function Investments({ getQueryData }) {
    const {
        getInvestCapacity,
        getInvestUsersCountByPeriod,
        getInvestDepositStatistics,
    } = useRequestsMethods();
    const dateOptions = [
        {
            label: "Week",
            value: getWeek(),
        },
        {
            label: "Month",
            value: getMonth(),
        },
        {
            label: "Quarter",
            value: getQuarter(),
        },
        {
            label: "Year",
            value: getYear(),
        },
        {
            label: "All Time",
            value: getAllTime(),
        },
    ];
    const [userDataDate, setUserDataDate] = useState(dateOptions[0]);
    const [depostitsDataDate, setDepostitsDataDate] = useState(dateOptions[0]);
    // console.log(getInvestDepositStatistics);
    const {
        data: investCapaData,
        isError: investCapaDataError,
        isLoading: investCapaDataLoading,
    } = useQuery({
        queryKey: ["investCapa"],
        queryFn: loadCapacityData,
    });
    const { data: usersData = { x: [], y: [] }, isError: usersDataError } =
        useQuery({
            queryKey: ["usersCountByPeriod", userDataDate],
            queryFn: () => loadUsersData(userDataDate.value),
        });
    const {
        data: depostitsData,
        isError: depostitsDataError,
        isLoading: depostitsDataLoading,
    } = useQuery({
        queryKey: ["DepositStatistics", depostitsDataDate],
        queryFn: () => loadDepositsData(depostitsDataDate.value),
        placeholderData: keepPreviousData,
    });

    return (
        <div className="investments">
            {!usersDataError && (
                <UsersCount
                    data={usersData}
                    dateOptions={dateOptions}
                    userDataDate={userDataDate}
                    changeDate={setUserDataDate}
                />
            )}
            {!depostitsDataError && !depostitsDataLoading && (
                <Deposits
                    data={depostitsData}
                    dateOptions={dateOptions}
                    depostitsDataDate={depostitsDataDate}
                    changeDate={setDepostitsDataDate}
                    getOptionName={getOptionName}
                />
            )}
            {!investCapaDataError && !investCapaDataLoading && (
                <Capacity data={investCapaData} getOptionName={getOptionName} />
            )}
        </div>
    );

    async function loadCapacityData() {
        const { data } = await getInvestCapacity();
        const { incomeStatisticObject, withdrawalStatisticObject } = data;
        const newIncomeStatisticObject = getNewData(incomeStatisticObject);
        const newWithdrawalStatisticObject = getNewData(
            withdrawalStatisticObject
        );
        // console.log(incomeStatisticObject);
        // console.log(newIncomeStatisticObject);
        return {
            incomeStatisticObject: newIncomeStatisticObject,
            withdrawalStatisticObject: newWithdrawalStatisticObject,
        };

        function getNewData(arr, changeOptions = false) {
            if (changeOptions) {
                for (let i = 0; i < arr.length; i++) {
                    arr[i].optionNumber = getOptionName(
                        arr[i].optionName,
                        true
                    );
                }
            }
            return arr.reduce((acc, curr) => {
                const { percent } = curr;
                const forX = percent * 100;
                const yIndex = percent * 10;

                const newData = {
                    x: forX,
                    y: curr.sum,
                };
                const returnData = {
                    ...acc,
                    [curr.optionNumber]: acc[curr.optionNumber]
                        ? {
                              ...acc[curr.optionNumber],
                              x: [...acc[curr.optionNumber].x, newData.x],
                          }
                        : {
                              x: [newData.x],
                              y: Array(10).fill(0, 0),
                          },
                };
                returnData[curr.optionNumber].x.sort((a, b) => a - b);
                returnData[curr.optionNumber].y[yIndex] = newData.y;
                return returnData;
            }, {});
        }
    }
    async function loadUsersData({ from, to }) {
        const { data } = await getInvestUsersCountByPeriod({
            // from: "2024-01-21",
            // to: "2024-01-30",
            from,
            to,
        });
        const r = data.reduce(
            (acc, curr) => {
                let [year, mounth, day] = curr.date;
                if (mounth < 10) mounth = mounth.toString().padStart(2, `0`);
                if (day < 10) day = day.toString().padStart(2, `0`);
                return {
                    x: [...acc.x, `${day}/${mounth}/${year}`],
                    y: [...acc.y, curr.count],
                };
            },
            {
                x: [],
                y: [],
            }
        );
        return r;
    }
    async function loadDepositsData({ from, to }) {
        const { data } = await getInvestDepositStatistics({
            // from: "2024-01-21",
            // to: "2024-01-30",
            from,
            to,
        });
        const { contribution, deposits, withdrawal } = data;

        const newContr = getNewData(contribution);
        const newDep = getNewData(deposits);
        const newWithd = getNewData(withdrawal, true);
        // console.log(newContr);
        // console.log(newDep);
        // console.log(newWithd);
        /*
        x: [],
                y: [],
                option: null,
        */

        return {
            contribution: newContr,
            deposits: newDep,
            withdrawal: newWithd,
        };

        function getNewData(arr, changeOptions = false) {
            if (changeOptions) {
                for (let i = 0; i < arr.length; i++) {
                    arr[i].optionNumber = getOptionName(
                        arr[i].optionName,
                        true
                    );
                }
            }
            return arr.reduce((acc, curr) => {
                let [year, mounth, day] = curr.date;
                if (mounth < 10) mounth = mounth.toString().padStart(2, `0`);
                if (day < 10) day = day.toString().padStart(2, `0`);
                const newData = {
                    x: `${day}/${mounth}/${year}`,
                    y: curr.amount,
                };
                return {
                    ...acc,
                    [curr.optionNumber]: acc[curr.optionNumber]
                        ? {
                              x: [...acc[curr.optionNumber].x, newData.x],
                              y: [...acc[curr.optionNumber].y, newData.y],
                          }
                        : {
                              x: [newData.x],
                              y: [newData.y],
                          },
                };
            }, {});
        }
    }
    function getOptionName(id, index = false) {
        const order = [
            "IRON",
            "ALUMINUM",
            "STEEL",
            "COPPER",
            "TITANIUM",
            "BRONZE",
            "SILVER",
            "GOLD",
            "PLATINUM",
            "PULSAR",
        ];
        // console.log(order.indexOf(id.toUpperCase()));
        return index ? order.indexOf(id.toUpperCase()) + 1 : order[id - 1];
    }
}
