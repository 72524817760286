import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        id: null,
        token: null,
        loading: false,
        error: false,
    },
    reducers: {
        loginStart: (state) => {
            state.loading = true;
            state.error = false;
        },
        loginSuccess: (state, action) => {
            return (state = {
                ...state,
                ...action.payload.userData,
                token: action.payload.token,
                loading: false,
            });
        },
        loginFailure: (state) => {
            state.loading = false;
            state.error = true;
        },
        logut: (state) => {
            return (state = {
                id: null,
                token: null,
                loading: false,
                error: false,
            });
        },
    },
});

export const { loginStart, loginSuccess, loginFailure, logut } =
    userSlice.actions;
export default userSlice.reducer;
