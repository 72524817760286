import { ReactComponent as UsersSvg } from "./svg/users.svg";
import { ReactComponent as OptionsArrowSvg } from "./svg/OptionArrow.svg";

import { ReactComponent as StatArrowSvg } from "../svg/statArrow.svg";

import { ReactComponent as ActiveSvg } from "./svg/users/active.svg";
import { ReactComponent as OnlineSvg } from "./svg/users/online.svg";
import { ReactComponent as ReffsSvg } from "./svg/users/reffs.svg";

import { useState } from "react";

export default function Users({ usersData, checkOnLoad }) {
    const [datePoint, setDatePoint] = useState("kkk");
    const { data, error, pending, errors } = usersData;
    // console.log();
    const [onlineCount, activeUsers, usersRefs] = data;
    if (error) {
        console.log(errors);
        return "Error";
    }
    return (
        <div className="info-block">
            <div className="title">
                <div className="text">
                    <UsersSvg /> Users
                </div>
                <div className="select-name">
                    <div className="headlist">
                        Week <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        <li className={chekClassForList("all")}>All time</li>
                        <li className={chekClassForList("year")}>Year</li>
                        <li className={chekClassForList("quarter")}>Quarter</li>
                        <li className={chekClassForList("month")}>Month</li>
                        <li className={chekClassForList("week")}>Week</li>
                    </ul>
                </div>
            </div>

            <div className="line-list">
                <div className="item">
                    <div className="key">
                        <ActiveSvg /> Active users
                    </div>
                    <div className="key">{checkOnLoad(activeUsers)}</div>
                    <div className="key">
                        <div className="status status-plus">
                            <StatArrowSvg /> +7 %
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="key">
                        <ReffsSvg /> Referrals
                    </div>
                    <div className="key">{checkOnLoad(usersRefs)}</div>
                    <div className="key">
                        <div className="status status-minus">
                            <StatArrowSvg /> -7 %
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="key">
                        <OnlineSvg /> Online
                    </div>
                    <div className="key">{checkOnLoad(onlineCount)}</div>
                    <div className="key">
                        <div className="status status-plus">
                            <StatArrowSvg /> +7 %
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function chekClassForList(type) {
        if (type === datePoint) return "active";
        return "";
    }
}
