import Left from "./left";
import Right from "./right";

export default function Dashboard({ getQueryData }) {
    return (
        <div className="dashboard flex-split">
            <Left getQueryData={getQueryData} />
            <Right getQueryData={getQueryData} />
        </div>
    );
}
