import { useState } from "react";
import { ReactComponent as UsersSvg } from "../left/svg/users.svg";
import { ReactComponent as OptionsArrowSvg } from "../left/svg/OptionArrow.svg";
import { ReactComponent as StatArrowSvg } from "../svg/statArrow.svg";

import { ReactComponent as ActiveSvg } from "../left/svg/users/active.svg";
// import { ReactComponent as OnlineSvg } from "../left/svg/users/online.svg";
import { ReactComponent as ReffsSvg } from "../left/svg/users/reffs.svg";

export default function Pull({ pullData, checkOnLoad }) {
    const [datePoint, setDatePoint] = useState("asdas");

    const { data, error, pending, errors } = pullData;
    const [LastGiveaway, PoolCapacity] = data;

    if (error) {
        console.log(errors);
        return "Error";
    }

    return (
        <div className="big-block bg-block info-block">
            <div className="title">
                <div className="text">
                    <UsersSvg /> Pull
                </div>
                <div className="select-name">
                    <div className="headlist">
                        Week <OptionsArrowSvg />
                    </div>
                    <ul className="list">
                        <li className={chekClassForList("all")}>All time</li>
                        <li className={chekClassForList("year")}>Year</li>
                        <li className={chekClassForList("quarter")}>Quarter</li>
                        <li className={chekClassForList("month")}>Month</li>
                        <li className={chekClassForList("week")}>Week</li>
                    </ul>
                </div>
            </div>

            <div className="line-list">
                <div className="item">
                    <div className="key">
                        <ActiveSvg /> Pool capacity
                    </div>
                    <div className="key">{checkOnLoad(PoolCapacity)}%</div>
                    <div className="key">
                        <div className="status status-plus">
                            <StatArrowSvg /> +7 %
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="key">
                        <ReffsSvg /> Closing the last giveaway (completed)
                    </div>
                    <div className="key">{checkOnLoad(LastGiveaway)}s</div>
                    <div className="key">
                        <div className="status status-minus">
                            <StatArrowSvg /> -7 %
                        </div>
                    </div>
                </div>
                {/* <div className="item">
                    <div className="key">
                        <ReffsSvg /> Closing the last giveaway
                    </div>
                    <div className="key">50:00:00</div>
                    <div className="key">
                        <div className="status status-minus">
                            <StatArrowSvg /> -7 %
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );

    function chekClassForList(type) {
        if (type === datePoint) return "active";
        return "";
    }
}
