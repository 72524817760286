function getQuarter() {
    const now = new Date();

    // Get today's date in the format 'YYYY-MM-DD'
    const to = formatDate(now);

    // Calculate the start and end dates for the previous quarter
    const quarterStart = getQuarterStartDate(now);
    const quarterEnd = getQuarterEndDate(quarterStart);

    // Get the start and end dates for the previous quarter in the format 'YYYY-MM-DD'
    const from = formatDate(quarterStart);

    return { from, to };
}

// Helper function to calculate the start date of the quarter for a given date
function getQuarterStartDate(date) {
    const year = date.getFullYear();
    const month = Math.floor(date.getMonth() / 3) * 3; // Start month of the quarter
    const day = "01"; // First day of the month
    return new Date(year, month, day);
}

// Helper function to calculate the end date of the quarter for a given start date
function getQuarterEndDate(startDate) {
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 3); // Add 3 months to get the end date of the quarter
    endDate.setDate(endDate.getDate() - 1); // Subtract 1 day to get the last day of the previous month
    return endDate;
}
function getMonth() {
    const now = new Date();

    // Get today's date in the format 'YYYY-MM-DD'
    const to = formatDate(now);

    // Calculate the date of a month ago
    const monthAgo = new Date(
        now.getFullYear(),
        now.getMonth() - 1,
        now.getDate()
    );

    // Get the date of a month ago in the format 'YYYY-MM-DD'
    const from = formatDate(monthAgo);

    return { from, to };
}
function getWeek() {
    const now = new Date();

    const to = formatDate(now);
    const weekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const from = formatDate(weekAgo);
    return { from, to };
}
function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

function getYear() {
    const now = new Date();

    // Get today's date in the format 'YYYY-MM-DD'
    const to = formatDate(now);

    // Calculate the start and end dates for the previous year
    const yearStart = getYearStartDate(now);
    const yearEnd = getYearEndDate(yearStart);

    // Get the start and end dates for the previous year in the format 'YYYY-MM-DD'
    const from = formatDate(yearStart);

    return { from, to };
}

// Helper function to calculate the start date of the year for a given date
function getYearStartDate(date) {
    const year = date.getFullYear() - 1; // Subtract 1 year to get the previous year
    const month = "00"; // January
    const day = "01"; // First day of the month
    return new Date(year, month, day);
}

// Helper function to calculate the end date of the year for a given start date
function getYearEndDate(startDate) {
    const endDate = new Date(startDate);
    endDate.setFullYear(endDate.getFullYear() + 1); // Add 1 year to get the end date of the year
    endDate.setDate(endDate.getDate() - 1); // Subtract 1 day to get the last day of the previous month
    return endDate;
}
function getAllTime() {
    const now = new Date();

    // Get today's date in the format 'YYYY-MM-DD'
    const to = formatDate(now);
    const from = "2024-01-01";

    return { from, to };
}

export { getQuarter, getMonth, getWeek, getYear, getAllTime };
