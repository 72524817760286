import { useQuery } from "@tanstack/react-query";
import useRequestsMethods from "../../../hook/useRequestsMethods";
import RadialOptions from "./Radial";
import { useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import LineGraph from "./LineGraph";

export default function Options() {
    const { getOptionByPeriod, getOptionAll } = useRequestsMethods();
    const [chartDate, setChartDate] = useState(false);
    const {
        data: optionByPeriodData,
        isError: optionByPeriodError,
        isLoading: optionByPeriodLoading,
    } = useQuery({
        queryKey: ["OptionByPeriod", chartDate],
        queryFn: () => getOptionData(chartDate),
        enabled: !!chartDate,
    });
    const {
        data: optionAllData = {
            incomeOptionStatisticObject: [],
            withdrawalOptionStatisticObject: [],
        },
        isError: optionAllError,
        isLoading: optionAllLoading,
    } = useQuery({
        queryKey: ["OptionAll"],
        queryFn: getOptionAllData,
    });
    // console.log(optionByPeriodData);
    // console.log(optionAllData);
    if (optionAllError || optionByPeriodError)
        return <h1>Errors.., check console</h1>;
    return (
        <div className="options investments">
            <RadialOptions
                dataAll={optionAllData}
                chartDate={chartDate}
                setChartDate={setChartDate}
                dataByDate={optionByPeriodData}
                dataToShow={chartDate ? optionByPeriodData : optionAllData}
            />
            {optionByPeriodData && !optionByPeriodLoading && (
                <LineGraph data={optionByPeriodData} />
            )}
        </div>
    );

    async function getOptionAllData() {
        const { data } = await getOptionAll();
        const { incomeOptionStatisticObject, withdrawalOptionStatisticObject } =
            data;
        const newIncome = genList(incomeOptionStatisticObject);
        const newWithdr = genList(withdrawalOptionStatisticObject);

        return {
            incomeOptionStatisticObject: newIncome,
            withdrawalOptionStatisticObject: newWithdr,
        };

        function genList(list) {
            return list.map((i) => ({
                value: i?.balance ? i.balance : i.income,
                name: getOptionName(i.optionNumber),
            }));
        }
    }
    async function getOptionData(date) {
        if (!date) return;
        const { startDate, endDate } = date;
        // console.log(startDate, endDate);
        const from = moment(startDate).format("YYYY-MM-DD");
        const to = moment(endDate).format("YYYY-MM-DD");
        var diffInDays = Math.abs(
            moment(startDate).diff(moment(endDate), "days")
        );
        if (diffInDays < 7) {
            // console.log(diffInDays);
            toast.error("Min 7 days to pick");
            setChartDate(false);
            return null;
        }

        const { data } = await getOptionByPeriod({
            // from: "2024-01-21",
            // to: "2024-01-30",
            from,
            to,
        });
        // console.log(data);
        let optionsList = new Set();
        const listOwnData = data.reduce(
            (acc, curr) => {
                const option = getOptionName(curr.optionNumber);
                let [year, mounth, day] = curr.date;
                if (mounth < 10) mounth = mounth.toString().padStart(2, `0`);
                if (day < 10) day = day.toString().padStart(2, `0`);
                const datePoint = `${day}/${mounth}/${year}`;
                // console.log(datePoint);
                const income = acc[option]
                    ? [...acc[option].income, curr.income]
                    : [curr.income];
                const withdrawal = acc[option]
                    ? [...acc[option].withdrawal, curr.withdrawal]
                    : [curr.withdrawal];
                const date = acc[option]
                    ? [...acc[option].date, datePoint]
                    : [datePoint];
                optionsList.add(option);
                return {
                    ...acc,
                    [option]: {
                        income,
                        withdrawal,
                        date,
                    },
                };
            },
            {
                /* [option]: {
                withd:[1,2,3,4],
                income:[1,2,3,4]
                date:[1,2,3,4]
            }*/
            }
        );
        optionsList = [...optionsList];
        const incomeOptionStatisticObject = [];
        const withdrawalOptionStatisticObject = [];
        // console.log(listOwnData);
        // console.log(optionsList.length);

        for (let i = 0; i < optionsList.length; i++) {
            const option = optionsList[i];
            const objData = listOwnData[option];
            const averIncome = calculateAverage(objData.income);
            const averWithd = calculateAverage(objData.withdrawal);
            incomeOptionStatisticObject.push({
                value: averIncome,
                name: option,
            });
            withdrawalOptionStatisticObject.push({
                value: averWithd,
                name: option,
            });
        }
        listOwnData.incomeOptionStatisticObject =
            incomeOptionStatisticObject.filter((i) => i.value > 0);
        listOwnData.withdrawalOptionStatisticObject =
            withdrawalOptionStatisticObject.filter((i) => i.value > 0);
        listOwnData.options = optionsList;
        // console.log(listOwnData);

        return listOwnData;

        function calculateAverage(numbers) {
            if (numbers.length === 0) {
                return 0;
            }

            var sum = numbers.reduce(function (a, b) {
                return a + b;
            });

            return +(sum / numbers.length).toFixed(2);
        }
    }

    function getOptionName(id, index = false) {
        const order = [
            "IRON",
            "ALUMINUM",
            "STEEL",
            "COPPER",
            "TITANIUM",
            "BRONZE",
            "SILVER",
            "GOLD",
            "PLATINUM",
            "PULSAR",
        ];
        // console.log(order.indexOf(id.toUpperCase()));
        return index ? order.indexOf(id.toUpperCase()) + 1 : order[id - 1];
    }
}
