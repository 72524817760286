import { useEffect, useRef, useState } from "react";
import useRequestsMethods from "../../hook/useRequestsMethods";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { ReactComponent as UserSvg } from "./svg/user.svg";
import { ReactComponent as MoneySvg } from "./svg/money.svg";
import { ReactComponent as HintSvg } from "./svg/hint.svg";
import { toast } from "react-toastify";
import moment from "moment";

export default function UserModal({ userTag = "", close }) {
    // const [data, setData] = useState(null);
    const {
        getUserInfo,
        updRefBalance,
        updBaseBalance,
        getWithdList,
        // getUsersList,
        banUser,
    } = useRequestsMethods();
    const [accStatus, setAccStatus] = useState(false);

    const [mainBalance, setMainBalance] = useState("");
    const [refBalance, setRefBalance] = useState("");

    const [listType, setListType] = useState(null);
    const statusList = ["PENDING", "APPROVED", "DECLINED"];
    const [withdType, setWithdType] = useState(statusList[0]);

    const [listPage, setListPage] = useState(0);

    const qc = useQueryClient();

    useEffect(() => {
        if (userTag) {
            document.body.style.overflow = "hidden";
        }
    }, []);

    const {
        data = null,
        isError,
        isLoading,
        error: ModalError,
    } = useQuery({
        queryKey: ["single_user", userTag],
        queryFn: () => userInfo(userTag),

        retry: false,
        refetchOnWindowFocus: false,
    });

    const {
        data: withdList = null,
        isError: withdError,
        isLoading: withdLoading,
    } = useQuery({
        queryKey: ["withd_single_user", listType, withdType, listPage],
        queryFn: () =>
            withdInfo({
                size: 10,
                page: listPage,
                status: withdType,
                userId: data.id,
            }),

        retry: false,
        refetchOnWindowFocus: false,
        enabled: listType === "withd",
    });

    const [isMouseDown, setIsMouseDown] = useState(false);
    const tableScrollRef = useRef(null);
    const defMousePos = {
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0,
    };
    const mouseCoords = useRef(defMousePos);

    useEffect(() => {
        if (data) {
            setAccStatus(data.status);
        }
    }, [data]);
    // console.log(data);

    if (data === undefined || isError) {
        return <Loading close={closeModal} error={ModalError} />;
    }
    if (!data || isLoading) return <Loading close={closeModal} />;
    return (
        <div className="user-modal" onClick={closeModal}>
            <div className="modal-body" onClick={(e) => e.stopPropagation()}>
                <div className="modal-head">
                    <h1>User Info</h1>
                    <div className="close" />
                </div>
                <div className="modal-info">
                    <div className="name">
                        {data.personImage ? (
                            <div className="logo">
                                <img src={data.personImage} alt="logo" />
                            </div>
                        ) : (
                            ""
                        )}
                        {data.personName}
                    </div>
                    <ul className="small-info">
                        <li>
                            Mail: <span> {data.email}</span>
                        </li>
                        <li>
                            ID: <span> {data.id}</span>
                        </li>
                    </ul>
                    <div className="inputs">
                        <div className="label">Account Status:</div>
                        <div className="own-pick">
                            <div className="own-head">{accStatus || ""}</div>
                            <div className="own-list">
                                <div
                                    className="itm"
                                    onClick={() => setAccStatus("ACTIVE")}
                                >
                                    ACTIVE
                                </div>
                                <div
                                    className="itm"
                                    onClick={() => setAccStatus("LOCKED")}
                                >
                                    LOCKED
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inputs">
                        <div className="label">Main Balance:</div>
                        <input
                            type="text"
                            value={mainBalance}
                            onChange={({ target }) =>
                                setMainBalance(target.value)
                            }
                            placeholder="Enter amount"
                        />
                        <div className="adit">
                            <div className="adit-info">
                                Current Balance:
                                <span>${data.balanceInfo.currentBalance}</span>
                            </div>
                            <div className="adit-info">
                                Balance:
                                <span>${data.balanceInfo.balance}</span>
                            </div>
                            <div className="adit-info">
                                Investment:
                                <span>${data.balanceInfo.investment}</span>
                            </div>
                            <div className="adit-info">
                                Negative Balance:
                                <span>${data.balanceInfo.negativeBalance}</span>
                            </div>
                            <div className="adit-info">
                                Withdrawal:
                                <span>${data.balanceInfo.withdrawal}</span>
                            </div>
                        </div>
                    </div>

                    <div className="inputs">
                        <div className="label">Ref. Balance:</div>
                        <input
                            type="text"
                            value={refBalance}
                            onChange={({ target }) =>
                                setRefBalance(target.value)
                            }
                            placeholder="Enter amount"
                        />
                        <div className="adit">
                            <div className="adit-info">
                                Balance:
                                <span>
                                    ${data.referralBalance.referralBalance}
                                </span>
                            </div>
                        </div>

                        <div className="refs">
                            <div className="levels">
                                {renderLevels(
                                    data.referralBalance.referralAmount
                                )}
                            </div>
                        </div>
                    </div>
                    <p>
                        Transactions Count:
                        {data.transactionViewHistoryList.length}
                    </p>
                    <div className="btns">
                        <div className="title">Show:</div>
                        <button
                            className={listType === "trans" ? "active" : ""}
                            data-type="trans"
                            onClick={pickBtnByType}
                        >
                            <span>Transactions</span>
                        </button>
                        <button
                            className={listType === "withd" ? "active" : ""}
                            data-type="withd"
                            onClick={pickBtnByType}
                        >
                            <span>Wtihdrawals</span>
                        </button>
                        <button
                            className={listType === "topups" ? "active" : ""}
                            data-type="topups"
                            onClick={pickBtnByType}
                        >
                            <span>Topups</span>
                        </button>
                    </div>
                    {renderSelectedList()}
                    <h2>To DO</h2>
                    <ol>
                        <li>Btn for load topups </li>
                        <li>Ban account</li>
                    </ol>
                </div>
                <div className="modal-btm">
                    <button onClick={goSave}>
                        <span>Save changes</span>
                    </button>
                </div>
            </div>
        </div>
    );

    function pickBtnByType({ currentTarget }) {
        const { type = null } = currentTarget.dataset;
        if (!type) return;

        setListType((prev) => (prev === type ? null : type));
        setListPage(0);
    }

    function renderSelectedList() {
        if (!listType) return "";

        switch (listType) {
            case "withd":
                // console.log(withdList);
                if (withdError) return <h3>Error while loading data</h3>;
                if (withdLoading) return <h3>Loading...</h3>;
                const { content, totalElements } = withdList;
                const totalPage =
                    totalElements > 0 ? Math.ceil(totalElements / 10) : 1;
                return (
                    <div className="user-selected-table">
                        <div className="head-info">
                            <div className="page-control">
                                <button>
                                    <span
                                        onClick={() => {
                                            setListPage((prev) => {
                                                const newPage = --prev;
                                                return newPage < 0
                                                    ? 0
                                                    : newPage;
                                            });
                                        }}
                                    >{`<-`}</span>
                                </button>
                                <button
                                    onClick={() => {
                                        setListPage((prev) => {
                                            const newPage = ++prev;
                                            return newPage + 1 > totalPage
                                                ? totalPage - 1
                                                : newPage;
                                        });
                                    }}
                                >
                                    <span>{`->`}</span>
                                </button>
                                <div className="sm-info">
                                    <div>
                                        Page {listPage + 1} of {totalPage}
                                    </div>
                                    <div>Total items: {totalElements}</div>
                                </div>
                            </div>
                            <div className="page-filters">
                                <div className="inputs">
                                    <div className="label">Status</div>

                                    <div className="own-pick">
                                        <div className="own-head">
                                            {withdType}
                                        </div>
                                        <div className="own-list">
                                            {renderListForOwnPick(
                                                statusList,
                                                setWithdType
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="table-holder"
                            ref={tableScrollRef}
                            onMouseDown={handleDragStart}
                            onMouseUp={handleDragEnd}
                            onMouseMove={handleDrag}
                        >
                            {renderTable(content)}
                        </div>
                    </div>
                );
            case "topups":
                return <div className="user-selected-table">Topups</div>;
            case "trans":
                const trans_content = data.transactionViewHistoryList || [];
                const totalPageTrans =
                    trans_content.length > 0
                        ? Math.ceil(trans_content.length / 10)
                        : 1;
                const start = listPage * 10;
                const end = start + 10;
                return (
                    <div className="user-selected-table">
                        <div className="head-info">
                            <div className="page-control">
                                <button>
                                    <span
                                        onClick={() => {
                                            setListPage((prev) => {
                                                const newPage = --prev;
                                                return newPage < 0
                                                    ? 0
                                                    : newPage;
                                            });
                                        }}
                                    >{`<-`}</span>
                                </button>
                                <button
                                    onClick={() => {
                                        setListPage((prev) => {
                                            const newPage = ++prev;
                                            return newPage + 1 > totalPageTrans
                                                ? totalPageTrans - 1
                                                : newPage;
                                        });
                                    }}
                                >
                                    <span>{`->`}</span>
                                </button>
                                <div className="sm-info">
                                    <div>
                                        Page {listPage + 1} of {totalPageTrans}
                                    </div>
                                    <div>
                                        Total items: {trans_content.length}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="table-holder"
                            ref={tableScrollRef}
                            onMouseDown={handleDragStart}
                            onMouseUp={handleDragEnd}
                            onMouseMove={handleDrag}
                        >
                            {renderTable(trans_content.slice(start, end))}
                        </div>
                    </div>
                );
            default:
                return "";
        }

        function renderTable(data = []) {
            console.log(data);
            if (!data || data === null || data.length <= 0) return "";
            const keys = Object.keys(data[0]);
            const head = keys.map((i, n) => {
                return <td key={n}>{i}</td>;
            });
            const body = data.map((i, n) => {
                return (
                    <tr key={n}>
                        {keys.map((k, nn) => {
                            let data = i[k];
                            // console.log(k)
                            if (k === "requestDateTime") {
                                const [Y, M, D, h, m] = data.map((d) =>
                                    d.toString().padStart(2, "0")
                                );
                                data = `${h}:${m} ${D}.${M}.${Y}`;
                            }
                            if (k === "dateTime") {
                                data = moment(data).format("HH:mm DD.MM.YY");
                            }
                            return <td key={nn + "_" + n}>{data}</td>;
                        })}
                    </tr>
                );
            });
            return (
                <table className="lst">
                    <thead>
                        <tr>{head}</tr>
                    </thead>
                    <tbody>{body}</tbody>
                </table>
            );
        }

        //DRAG
        function handleDragStart(e) {
            if (!tableScrollRef.current) return;
            const slider = tableScrollRef.current;
            const startX = e.pageX - slider.offsetLeft;
            const startY = e.pageY - slider.offsetTop;
            const scrollLeft = slider.scrollLeft;
            const scrollTop = slider.scrollTop;
            mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
            setIsMouseDown(true);
            slider.classList.toggle("grabbed");
            // document.body.style.cursor = "grabbing";
        }
        function handleDragEnd() {
            setIsMouseDown(false);
            if (!tableScrollRef.current) return;
            const slider = tableScrollRef.current;
            slider.classList.toggle("grabbed");

            // document.body.style.cursor = "default";
        }
        function handleDrag(e) {
            if (!isMouseDown || !tableScrollRef.current) return;
            e.preventDefault();
            const slider = tableScrollRef.current;
            const x = e.pageX - slider.offsetLeft;
            const y = e.pageY - slider.offsetTop;
            const walkX = (x - mouseCoords.current.startX) * 1.2;
            const walkY = (y - mouseCoords.current.startY) * 1.2;
            slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
            slider.scrollTop = mouseCoords.current.scrollTop - walkY;
            // console.log(walkX, walkY)
        }

        function renderListForOwnPick(list, func) {
            return list.map((i, k) => {
                return (
                    <div
                        className="itm"
                        key={k}
                        onClick={() => {
                            func(i);
                            setListPage(0);
                        }}
                    >
                        {i}
                    </div>
                );
            });
        }
    }
    async function goSave() {
        if (mainBalance) {
            const comment = window.prompt(
                "Type comment for 'Main Balance change'"
            );
            try {
                const { data: answer } = await updBaseBalance(
                    data.id,
                    mainBalance,
                    comment
                );
                // console.log(answer);
                setMainBalance("");
                toast.success("Main balance updated");
            } catch (err) {
                console.log(err);
                toast.error("Main balance wasn`t update");
            }
        }
        if (refBalance) {
            const comment = window.prompt(
                "Type comment for 'Ref. Balance change'"
            );
            try {
                const { data: answer } = await updRefBalance(
                    data.id,
                    refBalance,
                    comment
                );
                // console.log(answer);
                setRefBalance("");
                toast.success("Ref balance updated");
            } catch (err) {
                console.log(err);
                toast.error("Ref. balance wasn`t update");
            }
        }
        if (data.status !== accStatus) {
            try {
                const { data: answer } = await banUser(data.id);
                console.log(answer);
                toast.success("User`s status account changed");
            } catch (err) {
                console.log(err);
                toast.error("account status change error");
            }
        }

        qc.invalidateQueries(["single_user"]);
    }

    function closeModal() {
        document.body.style.overflow = "auto";
        close();
    }

    async function withdInfo(data) {
        try {
            const { data: answer } = await getWithdList(data);
            return answer;
        } catch (err) {
            return null;
        }
    }

    async function userInfo(mail, date = false) {
        // date isn`t use
        try {
            const { data } = await getUserInfo(date, mail);
            return data;
        } catch (err) {
            // console.log(err);
            throw err;
            // return undefined;
        }
    }

    function renderLevels(levels) {
        return levels
            .sort((a, b) => a.referralLevel - b.referralLevel)
            .map((item, index) => {
                return (
                    <div className="level" key={index}>
                        <div className="head">
                            <span>Level {item.referralLevel}</span>
                        </div>
                        <div className="users">
                            <UserSvg /> {item.referralCount}
                        </div>
                        <div className="money">
                            <MoneySvg /> <span>$</span>
                            {item.rewardBalanceSum}
                        </div>
                    </div>
                );
            });
    }
}

function Loading({ close, error = false }) {
    console.log(error.response);

    let ErrMsg = error?.response?.data?.message;
    if (ErrMsg && ErrMsg.includes("ResourceNotFoundException")) {
        ErrMsg = ErrMsg.split("ResourceNotFoundException:")[1];
    } else {
        ErrMsg = "Some fetch error";
    }
    return (
        <div className="user-modal loading" onClick={close}>
            <div className="modal-body" onClick={(e) => e.stopPropagation()}>
                <div className="modal-head">
                    {error ? (
                        <>
                            <h1>Error while parse data</h1>
                            <p>{ErrMsg}</p>
                        </>
                    ) : (
                        <h1>Loading</h1>
                    )}
                </div>
            </div>
        </div>
    );
}
