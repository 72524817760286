import { useQueries } from "@tanstack/react-query";
import useRequestsMethods from "../../../../hook/useRequestsMethods";
import Graph from "./Graph";
import Pull from "./Pull";

export default function Right({ getQueryData }) {
    const {
        getDashboardLastGiveawayCompleted,
        getDashboardPoolCapacity,
        getDashboardInvestmentsView,
        getDashboardUsersView,
    } = useRequestsMethods();

    const pullQueries = [
        {
            key: "LastGiveaway",
            fn: getDashboardLastGiveawayCompleted,
        },
        {
            key: "PoolCapacity",
            fn: getDashboardPoolCapacity,
        },
    ];
    const chartQueries = [
        {
            key: "InvestmentsView",
            fn: getDashboardInvestmentsView,
        },
        {
            key: "UsersView",
            fn: getDashboardUsersView,
        },
    ];
    const pullData = useQueries(getQueryData("pullStat", pullQueries));
    const chartData = useQueries(getQueryData("chartStat", chartQueries));
    // console.log(pullData);
    return (
        <div className="right">
            <Pull pullData={pullData} checkOnLoad={checkOnLoad} />
            <Graph chartData={chartData} />
        </div>
    );
    function checkOnLoad(data) {
        if (typeof data === "boolean") {
            return "Loading";
        }
        return data;
    }
}
