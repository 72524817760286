import { loginFailure, loginStart, loginSuccess, logut } from "./userRedux";

async function redux_cahngeUser(dispatch, userData, token = "none") {
    dispatch(loginSuccess({ userData, token }));
}

async function logoutUser(dispatch, cb = false) {
    if (cb) {
        try {
            // console.log(cb);
            await cb();
        } catch (err) {
            console.log("err logout api");
            console.log(err);
        }
    }
    // changeToken();
    clearCookies();
    dispatch(logut());
    // window.location.href = "/";
}

function clearCookies() {
    var cookies = document.cookie.split(";");
    console.log(cookies);

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
export { redux_cahngeUser, logoutUser };
