import { useQueries } from "@tanstack/react-query";
import useRequestsMethods from "../../../../hook/useRequestsMethods";
import Balance from "./Balance";
import BufferComponent from "./Buffer";
import Users from "./Users";

export default function Left({ getQueryData }) {
    const {
        //buffer
        getDashboardPoolReserve,
        getDashboardPoolSize,
        //users
        getDashboardOnlineCount,
        getDashboardActiveUser,
        getDashboardReferrals,

        // users-balance-controller
        getDashboardUsersWithdrawBalance,
        getDashboardUsersIncomeBalance,
        getDashboardUsersBalance,
        getDashboardUsersCommissionBalance,
    } = useRequestsMethods();

    const usersQueries = [
        {
            key: "onlineCount",
            fn: getDashboardOnlineCount,
        },
        {
            key: "activeUsers",
            fn: getDashboardActiveUser,
        },
        {
            key: "usersRefs",
            fn: getDashboardReferrals,
        },
    ];
    const bufferQueries = [
        {
            key: "bufferPoolReserve",
            fn: getDashboardPoolReserve,
        },
        {
            key: "bufferPoolSize",
            fn: getDashboardPoolSize,
        },
    ];
    const balanceQueries = [
        {
            key: "balanceWithdraw",
            fn: getDashboardUsersWithdrawBalance,
        },
        {
            key: "balanceIncome",
            fn: getDashboardUsersIncomeBalance,
        },
        {
            key: "balanceBalance",
            fn: getDashboardUsersBalance,
        },
        {
            key: "balanceCommission",
            fn: getDashboardUsersCommissionBalance,
        },

        // {
        //     key: "DashboardRefBal",
        //     fn: getDashboardRefBal,
        // },
        // {
        //     key: "DashboardRefFundBal",
        //     fn: getDashboardRefFundBal,
        // },
        // {
        //     key: "DashboardInvestFundBal",
        //     fn: getDashboardInvestFundBal,
        // },
    ];
    const usersData = useQueries(getQueryData("userStat", usersQueries));
    const bufferData = useQueries(getQueryData("bufferStat", bufferQueries));
    const balanceData = useQueries(getQueryData("balanceStat", balanceQueries));

    return (
        <div className="left big-block bg-block">
            <Users usersData={usersData} checkOnLoad={checkOnLoad} />
            <Balance balanceData={balanceData} checkOnLoad={checkOnLoad} />
            <BufferComponent
                bufferData={bufferData}
                checkOnLoad={checkOnLoad}
            />
        </div>
    );

    function checkOnLoad(data) {
        if (typeof data === "boolean") {
            return "Loading";
        }
        return data;
    }
}
